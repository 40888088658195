import styled, { keyframes } from "styled-components";
import Hero from "../components/Hero";
import { useState } from "react";
import Footer from "../components/Footer";
import Competencies from "../components/about us/Competencies";
import { mobile } from "../responsive";

const Shake = keyframes`
    0% {
        transform: rotate(2deg);
    }
    25% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(-2deg);
    }

`;

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;
const KeyPoints = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1e1c27;
`;
const List = styled.ul`
  display: flex;
  justify-content: center;
  width: 90%;
  gap: 30px;
  color: white;

  ${mobile({
    gap: "10px",
  })}
`;
const ListItem = styled.li`
  list-style: none;
  font-size: 54px;
  color: ${(props) => props.color};
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  letter-spacing: 2px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    height: 20px;
    width: 20px;
    background: white;
    border-radius: 50%;

    ${mobile({
      height: "5px",
      width: "5px",
    })}
  }
`;
const Box = styled.div`
  width: 90%;
  transition: .2s all ease-in-out;

  ${mobile({
    width: "100vw",
  })}
`;
const Slide = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 90%;
  padding: 50px;
  margin: auto;
  transition: 2s all ease-in-out;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    padding: "20px",
  })}
`;
const Info = styled.p`
  width: 50%;
  height: 100%;
  line-height: 1.6;
  font-size: 18px;
  color: white;

  ${mobile({
    width: "90%",
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  width: 400px;
  height: 300px;
  overflow: hidden;
  transition: 0.2s all ease-in-out;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "200px",
  })}
`;
const ValueContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("https://t3.ftcdn.net/jpg/03/22/30/46/240_F_322304683_7ysRarFkmy2osfPKTOYQv7qTPofKelfb.jpg");
  background-repeat: no-repeat;
  // background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mobile({
    padding: "20px 0px",
  })}
`;
const Title = styled.h1`
  font-size: 54px;
  letter-spacing: 2px;
  color: white;
  margin-top: 10px;

  ${mobile({
    fontSize: "25px",
    textTransform: "uppercase",
  })}
`;
const Wrapper = styled.div`
  width: 100vw;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 20px;
  margin-top: 20px;
`;
const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 350px;
  height: 300px;
  padding: 50px;
  position: relative;
  background: white;
  transition: 0.2s all linear;

  &:hover {
    animation-name: ${Shake};
    animation-duration: 0.2s;
  }

  ${mobile({
    width: "80vw",
    height: "200px",
  })}
`;
const Icon = styled.div`
  position: absolute;
  top: -30px;
  height: 80px;
  width: 80px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;

  ${mobile({
    height: "60px",
    width: "60px",
    fontSize: "25px",
  })}
`;
const Heading = styled.div`
  font-size: 34px;
  color: orange;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Point = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.3;

  ${mobile({
    fontSize: "14px",
  })}
`;
export default function About() {
  const [select, setSelect] = useState([true, false, false]);
  const handleClick = (i) => {
    let newArr = Array(3).fill(false);
    newArr[i] = true;
    setSelect(newArr);
  };

  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/05/39/65/40/240_F_539654005_M7XZRGAG3TAarymgapSSgSUdgkNKQL2G.jpg"
        title="About Us"
      />
      <Container>
        <KeyPoints>
          <List>
            <ListItem
              color={select[0] ? "orange" : ""}
              onClick={() => handleClick(0)}
            >
              Agile
            </ListItem>
            <Dot>
              <span></span>
            </Dot>
            <ListItem
              color={select[1] ? "orange" : ""}
              onClick={() => handleClick(1)}
            >
              Passionate
            </ListItem>
            <Dot>
              <span></span>
            </Dot>
            <ListItem
              color={select[2] ? "orange" : ""}
              onClick={() => handleClick(2)}
            >
              Committed
            </ListItem>
          </List>
          <Box>
            {select[0] ? (
              <Slide>
                <Info>
                  At MaVen Technologies, agility is at the core of our operations.
                  We embrace an agile mindset and methodologies to navigate the
                  fast-paced and ever-changing landscape of technology. Our
                  adaptive approach allows us to quickly respond to evolving
                  client needs, market trends, and emerging technologies. We
                  prioritize collaboration, flexibility, and transparency in our
                  processes, enabling us to deliver high-quality solutions
                  efficiently. By breaking down projects into manageable
                  iterations and fostering a culture of continuous improvement,
                  we ensure that we remain nimble, responsive, and deliver value
                  to our clients consistently. At MaVen Technologies, we embody
                  agility to drive success and innovation.
                </Info>
                <Image>
                  <img
                    src="https://t3.ftcdn.net/jpg/02/92/88/60/240_F_292886019_m0VkpPBBtabTa5Nta8MYesGxrhIHWGW8.jpg"
                    alt="agile"
                  />
                </Image>
              </Slide>
            ) : (
              ""
            )}
            {select[1] ? (
              <Slide>
                <Info>
                  At MaVen Technologies, we are driven by a deep passion for what
                  we do. We live and breathe technology, constantly seeking
                  innovative solutions that can make a positive impact on our
                  clients' businesses. Our team of dedicated professionals is
                  fueled by a genuine enthusiasm for pushing boundaries and
                  exceeding expectations. We approach every project with
                  unwavering commitment, pouring our expertise and creativity
                  into delivering exceptional results. Our passion drives us to
                  stay at the forefront of industry trends, continuously
                  learning and adapting to ensure we provide the best possible
                  solutions. At MaVen Technologies, passion is the driving force
                  behind our pursuit of excellence.
                </Info>
                <Image>
                  <img
                    src="https://t3.ftcdn.net/jpg/03/92/12/14/240_F_392121467_svMMMyFigWvBTsCbqve2cNm9xeqDPoZ7.jpg"
                    alt="passionate"
                  />
                </Image>
              </Slide>
            ) : (
              ""
            )}
            {select[2] ? (
              <Slide>
                <Info>
                  At MaVen Technologies, we are deeply committed to our clients'
                  success. We understand the trust they place in us, and we go
                  above and beyond to deliver exceptional value and exceed their
                  expectations. Our commitment is unwavering, as we invest our
                  time, expertise, and resources to ensure the success of every
                  project we undertake. We are dedicated to building strong,
                  long-lasting partnerships with our clients, based on trust,
                  transparency, and open communication. Our commitment extends
                  beyond project completion, as we strive to provide ongoing
                  support and assistance to help our clients thrive in a rapidly
                  changing technological landscape.
                </Info>
                <Image>
                  <img
                    src="https://t3.ftcdn.net/jpg/00/95/11/04/240_F_95110427_kBAVUcNvwNBHHIOiCQIebubc3sGGSof3.jpg"
                    alt="committed"
                  />
                </Image>
              </Slide>
            ) : (
              ""
            )}
          </Box>
        </KeyPoints>
        <ValueContainer>
          <Title>Why MaVen !</Title>
          <Wrapper>
            <Value>
              <Icon>
                <i class="fa-solid fa-shield-halved"></i>
              </Icon>
              <Heading>Integrity</Heading>
              <Point>We are honest, fair and deliver on our promises</Point>
            </Value>
            <Value>
              <Icon>
                <i class="fa-solid fa-thumbs-up"></i>
              </Icon>
              <Heading>Positive regard for people</Heading>
              <Point>
                We respect everyone and have unconditional positive regard for
                people
              </Point>
            </Value>
            <Value>
              <Icon>
                <i class="fa-solid fa-people-group"></i>
              </Icon>
              <Heading>Customer Enagement</Heading>
              <Point>
                We are empathetic, courteous and responsive to our customers
              </Point>
            </Value>
            <Value>
              <Icon>
                <i class="fa-solid fa-chart-simple"></i>
              </Icon>
              <Heading>Obsession for Excellence</Heading>
              <Point>
                We strive for highest standards and never get satisfied with the
                second best
              </Point>
            </Value>
            <Value>
              <Icon>
                <i class="fa-solid fa-circle-question"></i>
              </Icon>
              <Heading>Innately Curious</Heading>
              <Point>
                We are curious and learn continually to explore new
                possibilities
              </Point>
            </Value>
            <Value>
              <Icon>
                <i className="fa-solid fa-angle-left"></i>
              </Icon>
              <Heading>Quality</Heading>
              <Point>
                Excellence. Innovation. Quality. Uncompromising Standards
              </Point>
            </Value>
          </Wrapper>
        </ValueContainer>
      </Container>
      <Competencies />
      <Footer />
    </>
  );
}
