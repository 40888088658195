import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    gap: "0px",
    padding: "20px 0px",
  })}
`;
const Slide = styled.div`
  min-height: 60vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  height: 90%;
  width: 30vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "0px",
  })}
`;
const Navigator = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;
const Nav = styled(Link)`
  text-decoration: none;
  font-size: 18px;
  color: grey;
  color: ${(props) => props.color};
  &:hover {
    color: orange;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Help = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: black;
  color: white;

  ${mobile({
    width: "90vw",
  })}
`;
const HelpInfo = styled.p`
  line-height: 1.5;

  ${mobile({
    fontSize: "16px",
  })}
`;
export default function WarehouseAutomation() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/02/83/62/30/240_F_283623007_yintjp83DoLlI9xZEujkBbs3fs9assjh.jpg"
        title="Warehouse Automation"
      />
      <Container>
        <Slide>
          <Title>The clever industrial business you can feel</Title>
          <Info>
            This includes various forms of mechanized automation, refering to
            the use of robots and robotic systems in the warehouse. More costly
            to implement, physical automation only provides a reasonable ROI for
            larger high-volume warehouse and distribution center (DC)
            operations.
          </Info>
          <Image>
            <img
              src="https://t4.ftcdn.net/jpg/04/10/87/29/240_F_410872963_C01s63u6es8lh127rkMOxD0J0T9cLm3N.jpg"
              alt="warehouse-automation"
            />
          </Image>
        </Slide>
        <Palette>
          <Navigator>
            <Heading>Systems</Heading>
            <List>
              <ListItem>
                <Nav to="/robotic-automation">Robotic Automation</Nav>
              </ListItem>
              <ListItem>
                <Nav to="/conveyors">Conveyors</Nav>
              </ListItem>
              <ListItem>
                <Nav to="/special-purpose-machines">
                  Special Purpose Machines
                </Nav>
              </ListItem>
              <ListItem>
                <Nav to="/warehouse-automation">Warehouse Automation</Nav>
              </ListItem>
            </List>
          </Navigator>
          <Help>
            <Heading>Call now Or write a message</Heading>
            <HelpInfo>
              Please feel free to contact us with the simple Enquiry form on the
              below link with all mandatory details and our representatives will
              call you back shortly.
            </HelpInfo>
            <Nav to="/contact" color="white">
              Click here
            </Nav>
          </Help>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
