import { useState } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { mobile } from "../responsive";

const Container = styled.div`
  width: 100vw;
  min-height: 150vh;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("https://t3.ftcdn.net/jpg/06/04/60/38/240_F_604603887_CBVUopWlkyH5qcByuaxNuKvFv15KrPfO.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px;
  margin: auto;

  ${mobile({
    overflowX: "hidden",
    padding: "0px",
    margin: "0px",
  })}
`;
const NavList = styled.div`
  width: 90vw;
  margin: auto;

  ${mobile({
    padding: "20px",
  })}
`;
const Box = styled.div`
  width: 90vw;
  min-height: 150vh;
  min-height: 100vh;
  background: white;

  ${mobile({
    width: "100vw",
  })}
`;
const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;

  ${mobile({
    flexDirection: "column",
    background: "#f3f0f3",
  })}
`;
const ListItem = styled.li`
  font-size: 20px;
  height: 80px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: 0.2s all ease-in-out;
  color: white;
  color: ${(props) => props.color};

  &:hover {
    cursor: pointer;
    color: orange;
  }

  ${mobile({
    color: "black",
    height: "50px",
    fontSize: "16px",
  })}
`;
const Slide = styled.div`
  width: 100%;
  min-height: 120vh;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;

  ${mobile({
    width: "100vw",
    padding: "20px",
  })}
`;
const Title = styled.h1`
  text-align: center;
  font-size: 44px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 1px 1px lightgrey;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  line-height: 1.5;
  color: grey;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 50%;
  margin: auto;
  text-align: center;
  overflow: hidden;
  transition: 0.2s all ease-in-out;

  img {
    width: 100%;
    height: 100%;
  }
  &:hover img {
    transform: scale(1.1);
  }
`;
const Legend = styled.div`
  display: flex;
  min-height: 60vh;

  ${mobile({
    flexDirection: "column-reverse",
  })}
`;
const Left = styled.div`
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 20vh;

  ${mobile({
    width: "100%",
    fontSize: "14px",
    minHeight: "25vh",
  })}
`;
const Rect = styled.div`
  height: 60px;
  width: 150px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 20px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 60px;
    width: 5px;
    left: -5px;
    background: yellow;
  }
`;
const Right = styled.div`
  width: 50%;
  min-height: 30vh;

  ${mobile({
    width: "100%",
  })}
`;

export default function Industries() {
  const [select, setSelect] = useState([
    false,
    false,
    false,
    false,
    true,
    false,
  ]);
  const handleClick = (i) => {
    let newArr = Array(6).fill(false);
    newArr[i] = true;
    setSelect(newArr);
  };
  return (
    <>
      <Hero
        bg="https://t4.ftcdn.net/jpg/06/15/69/49/240_F_615694954_jHpZQKPbSNDJ8jOBg2JlCiim9DcFiuYs.jpg"
        title="Industries"
      />
      <Container>
        <NavList>
          <List>
            <ListItem
              onClick={() => handleClick(4)}
              bs={select[4] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[4] ? "orange" : ""}
            >
              Engineering
            </ListItem>

            <ListItem
              onClick={() => handleClick(1)}
              bs={select[1] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[1] ? "orange" : ""}
            >
              Energy
            </ListItem>
            <ListItem
              onClick={() => handleClick(2)}
              bs={select[2] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[2] ? "orange" : ""}
            >
              Electronics
            </ListItem>
            <ListItem
              onClick={() => handleClick(3)}
              bs={select[3] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[3] ? "orange" : ""}
            >
              Life Science
            </ListItem>
            <ListItem
              onClick={() => handleClick(0)}
              bs={select[0] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[0] ? "orange" : ""}
            >
              Transportation
            </ListItem>
            <ListItem
              onClick={() => handleClick(5)}
              bs={select[5] ? "0px -10px 20px 0px lightgrey" : ""}
              color={select[5] ? "orange" : ""}
            >
              Consumer Packaged Goods
            </ListItem>
          </List>
        </NavList>
        <Box>
          {select[0] ? (
            <Slide>
              <Title>Transportation</Title>
              <Info>
                <p>
                  Urbanization has become an ever-growing trend in modern
                  society, leading to heightened demand for transportation and
                  mobility services. This demand requires a corresponding
                  increase in the efficiency and reliability of transportation
                  operations. With the advent of technological advancements and
                  automation systems, it has become possible to enhance the
                  efficiency of transportation businesses. However, the adoption
                  of these systems can be challenging for businesses, especially
                  in this sector That is where MaVen Technology comes in,
                  providing support to our customers in the transportation
                  industry to successfully adopt efficient and reliable
                  automation systems. We provide assembly and testing solutions
                  for a few transportation applications like Fuel & Emission,
                  Electrical & Electronics, Interior & Safety, Engine
                  Management, and Drive Train & Chassis.
                </p>
                <br></br>
                <p>
                  Our expertise and experience in this sector allow us to
                  understand the specific needs and challenges faced by our
                  customers. We work closely with them to assess their current
                  operations and identify areas where automation can be
                  implemented to enhance their efficiency. This results in a
                  customized solution that meets their specific business
                  requirements.
                </p>
                <br></br>
                <p>
                  MaVen Technologies automation solutions not only benefit
                  transportation businesses but also have a significant impact
                  on the urban community. The adoption of automation systems
                  leads to an increase in the speed and reliability of
                  transportation services. This, in turn, results in reduced
                  wait times for passengers and quicker delivery of goods,
                  making transportation more convenient for the urban community.
                  The automation systems also help to reduce emissions,
                  contributing to a cleaner and greener environment for the
                  urban community.
                </p>
                <br></br>
                <p>
                  Moreover, our automation solutions are designed to be
                  user-friendly and intuitive, making it easy for transportation
                  businesses. to adopt and integrate these systems into their
                  existing operations. We provide ongoing support and
                  maintenance services to ensure the smooth functioning of the
                  systems.
                </p>
              </Info>
              <Image>
                <img
                  src="https://t4.ftcdn.net/jpg/03/82/94/01/240_F_382940101_BjuwXRaKR0tzdsDjk5VTR0c1tjyG24J4.jpg"
                  alt="transportation"
                />
              </Image>
            </Slide>
          ) : (
            ""
          )}
          {select[1] ? (
            <Slide>
              <Title>Energy</Title>
              <Info>
                <p>
                  Some applications, we provide assembly and testing solutions :
                </p>
              </Info>
              <Legend>
                <Left>
                  <Rect>MCB</Rect>
                  <Rect>MCCB</Rect>
                  <Rect>Contactor</Rect>
                  <Rect>Telecom Connector</Rect>
                  <Rect>Auxiliary Switches</Rect>
                  <Rect>Power Meters</Rect>
                </Left>
                <Right>
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={true}
                    infiniteLoop={true}
                  >
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/04/92/17/25/240_F_492172519_gaDHLVhHrEg5q8EdUaap3Qh8bgGjaZ3x.jpg"
                        alt="contactor"
                      />
                      <p className="legend">Contactor</p>
                    </div>
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/03/90/02/61/240_F_390026122_TZNerVS00EzhYN3LOMIyMogat8KHHqob.jpg"
                        alt="telecom connector"
                      />
                      <p className="legend">Telecom Connector</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/34/98/32/240_F_334983282_b9XCqGnocX3CkF3epvU2MqjJxLC9mvuD.jpg"
                        alt="auxiliary switches"
                      />
                      <p className="legend">Auxiliary Switches</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/01/28/80/46/240_F_128804678_7SM22MLX1qdPaCaPKPEQpETkYkPNRfEP.jpg"
                        alt="power meters"
                      />
                      <p className="legend">Power Meters</p>
                    </div>
                  </Carousel>
                </Right>
              </Legend>
              <Info>
                <p>
                  The importance of energy efficiency and automation in various
                  industrial applications has increased dramatically in recent
                  years and MaVen Technologies is one of the leading companies
                  that provide innovative energy automation solutions. Our
                  expertise in this field is unmatched and we have developed a
                  wide range of solutions to cater to the needs of different
                  industries.
                </p>
                <br></br>
                <p>
                  MaVen Technologies energy automation solutions are designed
                  specifically for assembly and testing applications. These
                  solutions are perfect for companies that manufacture{" "}
                  <b>
                    MCBs, MCCBs, Contactors, Telecom Connectors, Auxiliary
                    switches, and Power meters.
                  </b>{" "}
                  These solutions are highly advanced and can help companies to
                  save energy, reduce costs and improve production efficiency.
                </p>
                <br></br>
                <p>
                  One of the key features of MaVen Technologies energy
                  automation solutions is their ease of use. These solutions are
                  designed to be user-friendly and can be integrated into
                  existing systems with minimal disruption. The solutions are
                  also designed to be highly flexible-which means that they can
                  be adapted to suit the specific needs of each company. This
                  allows comppnies to make the most of their existing resources
                  and reduce their reliance on external contractors.
                </p>
                <br></br>
                <p>
                  Another benefit of MaVen Technologies energy automation
                  solutions is their reliability. These solutions are built
                  using high-quality components and are designed to operate in
                  even the harshest industrial environments. They are also
                  designed to be highly durable and require minimal maintenance,
                  which means that companies can save time and money in the long
                  run.
                </p>
              </Info>
            </Slide>
          ) : (
            ""
          )}
          {select[2] ? (
            <Slide>
              <Title>Electronics</Title>
              <Info>
                <p>
                  Accuracy and repeatability are the key terminologies in the
                  ever growing electronics industry. We employ the same
                  terminologies in the solutions we provide for our clients in
                  the industry giving them a competitive edge in the market.
                </p>
                <br></br>
                <p>
                  Some applications, we provide assembly and testing solutions:
                </p>
                <br></br>
                <p>
                  At MaVen Technologies, we understand the importance of precision
                  and consistency in the electronics industry. In today's fast-
                  paced market, customers demand high-quality products that meet
                  their expectations every time. As a result, we have made it
                  our mission to ensure that our clients have the tools and
                  resources they need to stay ahead of the competition.
                </p>
                <br></br>
                <p>
                  One of the ways we do this is by providing assembly and
                  testing solutions for a variety of applications, including
                </p>
              </Info>
              <Legend>
                <Left>
                  <Rect>RFID</Rect>
                  <Rect>Sensors</Rect>
                  <Rect>Electronic Circuit Boards</Rect>
                </Left>
                <Right>
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={true}
                    infiniteLoop={true}
                  >
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/06/01/97/49/240_F_601974955_MuTo9lRXxyg6FJMCu555CIPQRKWF2Wmx.jpg"
                        alt="rfid"
                      />
                      <p className="legend">RFID</p>
                    </div>
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/05/70/06/79/240_F_570067930_5i3gjubTGLDG6wUwKAMwqHe9MdrkQ5YC.jpg"
                        alt="sensors"
                      />
                      <p className="legend">Sensors</p>
                    </div>
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/05/97/62/21/240_F_597622133_Am8j4aKgGzJclYWD9ZxfnChnQfwQPqhV.jpg"
                        alt="electronic circuit board"
                      />
                      <p className="legend">Electronic Circuit Board</p>
                    </div>
                  </Carousel>
                </Right>
              </Legend>
              <Info>
                <p>
                  Our focus on accuracy and repeatability means that we use the
                  latest technology and techniques to deliver products that meet
                  the highest standards. Whether it's through our use of
                  automated assembly lines or our focus on quality control, we
                  are committed to delivering products that are reliable and
                  consistent.
                </p>
                <br></br>
                <p>
                  In addition to providing high-quality products, we also work
                  closely with our clients to understand their specific needs
                  and requirements. We take the time to understand the
                  challenges they face, and we provide customized solutions that
                  are tailored to their individual needs. Whether it's new
                  product development or an existing product line, we are
                  dedicated to helping our clients succeed in the ever-changing
                  electronics market.
                </p>
                <br></br>
                <p>
                  Ultimately, our goal is to help our clients build their brand
                  and reputation, by providing them with high-quality products
                  that meet the needs of their customers. We believe that by
                  working closely with our clients and providing them with the
                  best possible solutions, we can help them stay ahead of the
                  competition and achieve their goals. So, if you're looking for
                  a partner that can help you succeed in the electronics
                  industry, look no further than MaVen Technologies.
                </p>
              </Info>
            </Slide>
          ) : (
            ""
          )}
          {select[3] ? (
            <Slide>
              <Title>Life Science</Title>
              <Info>
                <p>
                  As human kind benefits from the rapidly advancing medical and
                  healthcare sector, we take pride in associating with the
                  sector. Along with the productivity and quality benefits we
                  offer to this industry, there is always a sense of joy and
                  satisfaction associated with them, of being truly touch lives.
                </p>
                <br></br>
                <p>
                  Some applications, we provide assembly and testing solutions:
                </p>
              </Info>
              <Legend>
                <Left>
                  <Rect>Inhaler</Rect>
                  <Rect>Blood Bag</Rect>
                  <Rect>I.V Cannula</Rect>
                  <Rect>Insulin Pen</Rect>
                </Left>
                <Right>
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={true}
                    infiniteLoop={true}
                  >
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/01/27/75/29/240_F_127752959_fKsfxM2Fd2rKHHbtriTKEqwTjM98v37R.jpg"
                        alt="inhaler"
                      />
                      <p className="legend">Inhaler</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/25/96/20/240_F_325962093_XD1PcwPQeSDHyoc0CrB9sgaATV8AxaCK.jpg"
                        alt="blood bag"
                      />
                      <p className="legend">Blood Bag</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/00/78/89/76/240_F_78897671_d8FGFw0lesXgkNW7ASCqrjukaugtVxRy.jpg"
                        alt="i.v cannula"
                      />
                      <p className="legend">I.V Cannula</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/63/97/04/240_F_363970472_eZtfUin8hk89xgvh7RoAz4NDpFg1pAIJ.jpg"
                        alt="safety mask"
                      />
                      <p className="legend">Safety Mask</p>
                    </div>
                  </Carousel>
                </Right>
              </Legend>
              <Info>
                <p>
                  Life sciences encompass many scientific disciplines that
                  explore the intricacies of living organisms, from the tiniest
                  cellular structures to complex ecosystems. As humankind
                  continues to make remarkable strides in medical and healthcare
                  advancements, the field of life sciences has become
                  increasingly vital. At the forefront of these advancements are
                  medical devices that have revolutionized how we diagnose,
                  treat, and manage diseases.
                </p>
                <br></br>
                <p>
                  <b>Medical device manufacturers</b> like us take pride in
                  contributing to the healthcare sector by offering productivity
                  and quality benefits. Our assembly and testing solutions play
                  a crucial role in ensuring the reliability and safety of
                  medical devices. Our work not only enhances the productivity
                  of the healthcare sector but also brings a sense of joy and
                  satisfaction in truly touching lives.
                </p>
                <br></br>
                <p>
                  One of the medical devices we specialize in is the inhaler, a
                  device that delivers medication directly to the lungs.
                  Inhalers are used widely to treat respiratory conditions such
                  as asthma and chronic obstructive pulmonary disease (COPD).
                  Our <b>assembly and testing solutions</b> ensure that the
                  inhalers are reliable, safe, and effective in delivering the
                  right amount of medication.
                </p>
                <br></br>
                <p>
                  Another critical medical device we specialize in is the blood
                  bag. Blood transfusions are a lifesaving procedure for
                  patients who have lost blood due to injury or surgery. Our
                  assembly and testing solutions ensure that the blood bags are
                  sterile and contamination-free, which is crucial to prevent
                  patient infections.
                </p>
                <br></br>
                <p>
                  We also offer{" "}
                  <b>assembly and resting solutions for IV cannulas,</b>
                  which administer medication, fluids, and nutrition directly
                  into the bloodstream. Our solutions ensure that the IV
                  cannulas are reliable and safe, minimizing the risk of
                  complications such as infection or blood clots.
                </p>
                <br></br>
                <p>
                  Moreover, we specialize in assembly and testing solutions for
                  insulin pens, which people with diabetes widely use to
                  administer insulin. Our solutions ensure that the insulin pens
                  are reliable, safe, and accurate in delivering the right
                  amount of insulin, which is crucial for managing diabetes.
                </p>
                <br></br>
                <p>
                  In conclusion, medical device manufacturers like us are
                  crucial in advancing the healthcare sector by offering
                  productivity and quality benefits. Our assembly and testing
                  solutions ensure the reliability and{" "}
                  <b>safety of medical devices,</b> bringing a sense of joy and
                  satisfaction in truly touching lives. The inhaler, blood bag,
                  IV cannulas, and insulin pen are just a few examples of the
                  medical devices we specialize in, but there are many more. As
                  the healthcare sector evolves, we will continue to innovate
                  and provide cutting-edge solutions to support the industry's
                  needs.
                </p>
              </Info>
            </Slide>
          ) : (
            ""
          )}
          {select[4] ? (
            <Slide>
              <Title>Engineering</Title>
              <Info>
                <p>
                  The challenging applications in the wide spread engineering
                  sector keeps us on our toes and also is a key factor to drive
                  innovation and research in our business. The applications in
                  this sector being limitless, we see great potential
                  opportunities to apply our knowledge and expertise in Serving
                  the same.
                </p>
                <br></br>
                <p>
                  Some applications, we provide assembly and testing solutions:
                </p>
              </Info>
              <Legend>
                <Left>
                  <Rect>Solar Wafer Handling</Rect>
                  <Rect>Battery lead plate stacking</Rect>
                  <Rect>Pneumatic</Rect>
                  <Rect>Bearing Endurance Test System</Rect>
                </Left>
                <Right>
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={true}
                    infiniteLoop={true}
                  >
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/43/60/44/240_F_343604483_qP81hsWM5Th4pfUVkXhJyen2EV4e2MSi.jpg"
                        alt="pneumatic valve"
                      />
                      <p className="legend">Pneumatic Valve</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/25/96/20/240_F_325962093_XD1PcwPQeSDHyoc0CrB9sgaATV8AxaCK.jpg"
                        alt="blood bag"
                      />
                      <p className="legend">Blood Bag</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/00/78/89/76/240_F_78897671_d8FGFw0lesXgkNW7ASCqrjukaugtVxRy.jpg"
                        alt="i.v cannula"
                      />
                      <p className="legend">I.V Cannula</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/03/63/97/04/240_F_363970472_eZtfUin8hk89xgvh7RoAz4NDpFg1pAIJ.jpg"
                        alt="safety mask"
                      />
                      <p className="legend">Safety Mask</p>
                    </div>
                  </Carousel>
                </Right>
              </Legend>
              <Info>
                <p>
                  Life sciences encompass many scientific disciplines that
                  explore the intricacies of living organisms, from the tiniest
                  cellular structures to complex ecosystems. As humankind
                  continues to make remarkable strides in medical and healthcare
                  advancements, the field of life sciences has become
                  increasingly vital. At the forefront of these advancements are
                  medical devices that have revolutionized how we diagnose,
                  treat, and manage diseases.
                </p>
                <br></br>
                <p>
                  <b>Medical device manufacturers</b> like us take pride in
                  contributing to the healthcare sector by offering productivity
                  and quality benefits. Our assembly and testing solutions play
                  a crucial role in ensuring the reliability and safety of
                  medical devices. Our work not only enhances the productivity
                  of the healthcare sector but also brings a sense of joy and
                  satisfaction in truly touching lives.
                </p>
                <br></br>
                <p>
                  One of the medical devices we specialize in is the inhaler, a
                  device that delivers medication directly to the lungs.
                  Inhalers are used widely to treat respiratory conditions such
                  as asthma and chronic obstructive pulmonary disease (COPD).
                  Our <b>assembly and testing solutions</b> ensure that the
                  inhalers are reliable, safe, and effective in delivering the
                  right amount of medication.
                </p>
                <br></br>
                <p>
                  Another critical medical device we specialize in is the blood
                  bag. Blood transfusions are a lifesaving procedure for
                  patients who have lost blood due to injury or surgery. Our
                  assembly and testing solutions ensure that the blood bags are
                  sterile and contamination-free, which is crucial to prevent
                  patient infections.
                </p>
                <br></br>
                <p>
                  We also offer{" "}
                  <b>assembly and resting solutions for IV cannulas,</b>
                  which administer medication, fluids, and nutrition directly
                  into the bloodstream. Our solutions ensure that the IV
                  cannulas are reliable and safe, minimizing the risk of
                  complications such as infection or blood clots.
                </p>
                <br></br>
                <p>
                  Moreover, we specialize in assembly and testing solutions for
                  insulin pens, which people with diabetes widely use to
                  administer insulin. Our solutions ensure that the insulin pens
                  are reliable, safe, and accurate in delivering the right
                  amount of insulin, which is crucial for managing diabetes.
                </p>
                <br></br>
                <p>
                  In conclusion, medical device manufacturers like us are
                  crucial in advancing the healthcare sector by offering
                  productivity and quality benefits. Our assembly and testing
                  solutions ensure the reliability and{" "}
                  <b>safety of medical devices,</b> bringing a sense of joy and
                  satisfaction in truly touching lives. The inhaler, blood bag,
                  IV cannulas, and insulin pen are just a few examples of the
                  medical devices we specialize in, but there are many more. As
                  the healthcare sector evolves, we will continue to innovate
                  and provide cutting-edge solutions to support the industry's
                  needs.
                </p>
              </Info>{" "}
            </Slide>
          ) : (
            ""
          )}
          {select[5] ? (
            <Slide>
              <Title>Consumer Packaged Goods</Title>
              <Info>
                <p>
                  Organizations in consumer products industry have challenges to
                  meet the changing demands of the consumers, maneuvering
                  through a consolidating market, and executing strategies to
                  grow profitably. Automation being the inevitable need for this
                  industry to meet some of its challenges, our automation
                  Solutions have been able to provide a competitive edge to our
                  clients.
                </p>
                <br></br>
                <p>
                  Some applications, we provide assembly and testing solutions:
                </p>
              </Info>
              <Legend>
                <Left>
                  <Rect>Pencil Sorting</Rect>
                  <Rect>Dispenser Pump Assembly</Rect>
                  <Rect>Cap Closure Assembly</Rect>
                  <Rect>High Speed Blade Stacking System</Rect>
                </Left>
                <Right>
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={true}
                    infiniteLoop={true}
                  >
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/00/88/14/54/240_F_88145473_aa5r7gx1BkClu7BJnj7XxL5YiC0sUrZv.jpg"
                        alt="dispenser Pump"
                      />
                      <p className="legend">Dispenser Pump</p>
                    </div>
                    <div>
                      <img
                        src="https://t4.ftcdn.net/jpg/00/86/70/41/240_F_86704132_5A26HVeognZcgOoqezHbpKuE13pvVaKR.jpg"
                        alt="closure cap"
                      />
                      <p className="legend">Closure Cap</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/01/32/25/18/240_F_132251831_bOMweN1Z5Ws5yscXYOXvHnsTPpEBQ0pr.jpg"
                        alt="secondary packaging"
                      />
                      <p className="legend">Secondary packaging</p>
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/00/33/20/90/240_F_33209059_8JpZSiJW0a1z23IlWF45ZGTJwZeUgitf.jpg"
                        alt="pencil"
                      />
                      <p className="legend">Pencil</p>
                    </div>
                  </Carousel>
                </Right>
              </Legend>
              <Info>
                <p>
                  The <b>consumer packaged goods industry</b> is a dynamic and
                  ever-evolving sector, with companies always facing new
                  challenges as they strive to meet changing needs of their
                  customers, navigate a consolidating market, and execute
                  strategies to grow profitably. In this context, automation has
                  emerged as an inevitable need for the industry to meet some of
                  its challenges. Many companies are turning to automation
                  solutions to gain a competitive edge.
                </p>
                <br></br>
                <p>
                  One of the key benefits of automation in the consumer packaged
                  goods industry is that it can help companies to improve their
                  production efficiency. reduce labor costs, and enhance product
                  quality. <b>Automation solutions</b> can also enable companies
                  to increase their output while maintaining consistent quality.
                  This is especially important in industries such as food and
                  beverage, where safety and hygiene standards are critical.
                </p>
                <br></br>
                <p>
                  With our automation solutions, we have been working with
                  companies in the consumer products industry for many years,
                  providing a range of assembly and testing solutions to help
                  them meet their unique needs and challenges. Our applications
                  include pencil sorting, dispenser pump assembly, cap closure
                  assembly, and high-speed blade stacking systems.
                </p>
                <br></br>
                <p>
                  Our pencil sorting system, for example, is designed to help
                  companies in the writing <b>instruments industry</b> to sort
                  and package pencils quickly and efficiently. The system can
                  handle different types of pencils and sort them based on size,
                  color, and other parameters. This can help companies to reduce
                  their labor costs, improve their production efficiency, and
                  enhance the quality of their finished products.
                </p>
                <br></br>
                <p>
                  Our dispenser pump assembly solution is another example of how
                  automation can benefit the consumer packaged goods industry.
                  Dispenser pumps are used in various products, including
                  personal care products, cleaning solutions, and
                  pharmaceuticals, and they need to be assembled with precision
                  to ensure that they work correctly. Our automated assembly
                  solution can help companies assemble dispenser pumps quickly
                  and accurately reducing labor costs and improving production
                  efficiency.
                </p>
                <br></br>
                <p>
                  In addition to <b>assembly solutions</b>, we also provide
                  testing solutions to help companies ensure their products'
                  quality and reliability. For example, our cap closure assembly
                  solution can be integrated with a leak testing system to
                  detect any defects in the caps, ensuring that they are
                  appropriately sealed and preventing leakage. Similarly, MaVen
                  Technologyies high-speed blade stacking system can be
                  integrated with a vision inspection system that can detect any
                  defects in the blades, ensuring that they meet the required
                  standards for quality and safety.
                </p>
                <br></br>
                <p>
                  Overall, automation has emerged as a critical need for
                  consumer product companies. With our expertise in automation
                  and our focus on delivering high-quality solutions, we can
                  help companies improve their production efficiency, reduce
                  labor costs, and enhance the quality of their finished
                  products. Our automation solutions can provide various
                  assembly and testing solutions that can help you meet your
                  unique business needs and challenges.
                </p>
              </Info>{" "}
            </Slide>
          ) : (
            ""
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
