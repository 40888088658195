import { useRef } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { iphone, mobile } from "../responsive";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mobile({
    flexDirection: "column",
    padding: "20px 0px",
  })}
`;
const Slide = styled.div`
  flex: 2;
`;
const ContactForm = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    flexDirection: "column",
  })}
`;
const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  ${mobile({
    padding: "0px 20px",
  })}
`;
const Title = styled.h2`
  font-size: 54px;
  letter-spacing: 2px;

  ${mobile({
    fontSize: "30px",
    textTransform: "uppercase",
    letterSpacing: "1px",
  })}
`;
const Text = styled.p`
  font-size: 18px;
  color: black;
  letter-spacing: 0.5px;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const Content = styled.div`
  display: flex;
  gap: 20px;

  ${mobile({
    flexDirection: "column",
    gap: "12px",
  })}
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${mobile({
    gap: "12px",
    minHeight: "40vh",
  })}
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${mobile({
    gap: "12px",
  })}
`;
const Input = styled.input`
  width: 350px;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;

  ${mobile({
    fontSize: "14px",
  })}
`;
const TextArea = styled.textarea`
  width: 350px;
  height: 200px;
  font-size: 16px;
  padding: 10px;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: orange;
  color: white;
  border: none;
  font-size: 1.1rem;
  transform: 1s ease all;
  pointer: cursor;

  &:active {
    transform: scale(0.7);
  }
`;
const Palette = styled.div`
  height: 90%;
  width: 30vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "20px",
  })}
`;
const Navigator = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    padding: "40px",
    width: "90vw",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Help = styled.div`
  width: 25vw;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: black;
  color: white;

  ${mobile({
    width: "90vw",
  })}
`;
const HelpInfo = styled.p`
  line-height: 1.5;

  ${mobile({
    fontSize: "14px",
  })}
`;
const List = styled.ul`
  list-style: none;
  width: 100%;
`;
const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;
const Icon = styled.div`
  height: 50px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: orange;
`;
const Info = styled.p`
  flex: 2;
  line-height: 1.5;
  a {
    text-decoration: none;
    color: white;
  }
  &:hover a {
    color: orange;
  }

  ${mobile({
    fontSize: "14px",
  })}
`;
const Map = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;

  ${iphone({
    display: "none",
  })}

  ${mobile({
    display: "none",
  })}
`;
const MobileMap = styled.div`
  display: none;

  ${mobile({
    display: "flex",
    minHeight: "30vh",
    width: "100vw",
  })}
`;

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_npqvwfc",
        "template_8rrqcnf",
        form.current,
        "Juz2ENa1znTbxlz38"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const notify = () => {
    toast.success("Message is sent!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <>
      <Hero
        bg="https://t4.ftcdn.net/jpg/04/81/72/77/240_F_481727770_1u2ylNpie8WRosMVbv1COXDnnEK6ofwh.jpg"
        title="Contact Us"
      />
      <Container>
        <ContactForm>
          <Head>
            <Title>Get in touch</Title>
            <Text>
              Please fill out the form below and we shall respond to all
              queries.
            </Text>
          </Head>
          <Body ref={form} onSubmit={sendEmail}>
            <Content>
              <Left>
                <Text>Name*</Text>
                <Input name="from_name" />
                <Text>Email*</Text>
                <Input name="from_email" />
                <Text>Contact Number</Text>
                <Input name="phone" />
                <Text>Organisation Name</Text>
                <Input name="organisation" />
              </Left>
              <Right>
                <Text>Subject</Text>
                <Input
                  placeholder="Let us know your topic of interest..!"
                  name="subject"
                />
                <Text>Your Message</Text>
                <TextArea name="message" />
              </Right>
            </Content>
            <Button>Send Message</Button>
            <ToastContainer />
          </Body>
        </ContactForm>
        <Slide>
          <Palette>
            <Navigator>
              <Heading>Call now or Write a message</Heading>
              <HelpInfo>
                Please feel free to contact us with the simple Enquiry form on
                the same page with all mandatory details and our representatives
                will call you back shortly.
              </HelpInfo>
            </Navigator>
            <Help>
              <List>
                <ListItem>
                  <Icon>
                    <i class="fa-solid fa-envelope"></i>
                  </Icon>
                  <Info>
                    <a
                      href="mailto: maventechnologies55@gmail.com"
                      rel="noopener noreferrer"
                    >
                      maventechnologies55@gmail.com
                    </a>
                  </Info>
                </ListItem>
                <ListItem>
                  <Icon>
                    <i class="fa-solid fa-phone-volume"></i>{" "}
                  </Icon>
                  <Info>
                    <a href="tel: +919940783337" rel="noopener noreferrer">
                      99407 83337
                    </a>
                  </Info>
                </ListItem>
                <ListItem>
                  <Icon>
                    <i class="fa-solid fa-location-dot"></i>{" "}
                  </Icon>
                  <Info>
                    No-36/19 Pillayar Koil 4th Street Ekkattuthangal-600032
                  </Info>
                </ListItem>
              </List>
            </Help>
          </Palette>
        </Slide>
      </Container>
      <Map>
        <iframe
          width="100%"
          height="1000"
          src="https://maps.google.com/maps?width=100%25&amp;height=1000&amp;hl=en&amp;q=no%2036/19%20pillayar%20koil%204th%20street%20ekkattuthangal+(Maven%20Technology)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          title="Maven Technology"
        >
          <a href="https://www.maps.ie/population/" rel="noopener noreferrer">
            Calculate population in area
          </a>
        </iframe>
      </Map>
      <MobileMap>
        <iframe
          width="100%"
          height="300"
          src="https://maps.google.com/maps?width=100%25&amp;height=1000&amp;hl=en&amp;q=no%2036/19%20pillayar%20koil%204th%20street%20ekkattuthangal+(Maven%20Technology)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          title="Maven Technology"
        >
          <a href="https://www.maps.ie/population/" rel="noopener noreferrer">
            Calculate population in area
          </a>
        </iframe>
      </MobileMap>
      <Footer />
    </>
  );
}
