import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Navbar from "../Navbar";
import { iphone, mobile } from "../../responsive";

const Slide = styled.div`
  height: 100vh;
  width: 100vw;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${mobile({
    backgroundSize: "contain-box",
    minHeight: "50vh",
    maxHeight: "50vh",
  })}

  ${iphone({
    minHeight: "50vh",
    maxHeight: "70vh",
  })}
`;
const Hero = styled.div`
  margin: 4rem;
  margin-top: 16rem;
  padding: 2rem;
  height: 60vh;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mobile({
    width: "100%",
    margin: "0px",
    marginTop: "10rem",
    gap: "10px",
  })}
`;
const Title = styled.h1`
  font-size: 54px;
  width: 60%;
  letter-spacing: 2px;
  line-height: 60px;
  text-align: start;
  text-shadow: 1px 1px black;

  ${mobile({
    fontSize: "20px",
    width: "100%",
    letterSpacing: "1px",
    lineHeight: "30px",
  })}
`;
const Caption = styled.h4`
  font-size: 20px;
  width: 50%;
  font-weight: normal;
  text-align: start;
  line-height: 1.5;
  text-shadow: 1px 1px black;

  ${mobile({
    width: "100%",
    fontSize: "14px",
  })}
`;

export default function Slider() {
  const Slides = [
    {
      url: "https://t4.ftcdn.net/jpg/06/12/37/77/240_F_612377740_HUIOKfVEJCJW7Mtyc8n3N6LGCytJh8Gp.jpg",
    },
    {
      url: "https://t3.ftcdn.net/jpg/06/14/58/46/240_F_614584625_9A0pzbAcInUKdkDLvRLtaPmvxHYX0xUS.jpg",
    },
    {
      url: "https://t4.ftcdn.net/jpg/01/96/43/57/240_F_196435727_VtvII0N6qhJLL0zIxfRdNmWOKyCcFPra.jpg",
    },
  ];
  return (
    <>
      <Navbar />
      <Carousel
        showArrows={true}
        autoPlay={true}
        emulateTouch={true}
        swipeable={true}
        showThumbs={true}
        infiniteLoop={true}
      >
        <div>
          <Slide bg={Slides[0].url}>
            <Hero>
              <Title>CONSISTENCY THE KEY FOR EFFECTIVE OPERATION</Title>
              <Caption>
                Our swift and efficient robots are used in a wide range of
                sealing & dispensing applications
              </Caption>
            </Hero>
          </Slide>
        </div>
        <div>
          <Slide bg={Slides[2].url}>
            <Hero>
              <Title>NURTURING PASSION FOR ENGINEERING AND TECHNOLOGY</Title>
            </Hero>
          </Slide>
        </div>
        <div>
          <Slide bg={Slides[1].url}>
            <Hero>
              <Title>FLEXIBLE SOLUTIONS THAT SAVE COSTS</Title>
              <Caption>
                Major challenges of automated palletizing such as patterned
                flexibility, tool flexibility etc are addressed by our robots
              </Caption>
            </Hero>
          </Slide>
        </div>
      </Carousel>
    </>
  );
}
