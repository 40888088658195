import styled, { keyframes } from "styled-components";
import { iphone, mobile, tablet } from "../../responsive";

const AnimateWords = keyframes`
  0% {
    transform: translateY(0%);
  }
  33% {
    transform: translateY(-100%);
  }
  66% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(-300%);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #1e1c27;
  color: white;
  font-family: "Geologica", sans-serif;
  margin-top: -3rem;
  overflow-x: hidden;

  ${iphone({
    height: "150vh",
    minHeight: "150vh",
    maxHeight: "200vh",
  })}

  ${mobile({
    gap: "10px",
    minHeight: "100vh",
    maxHeight: "200vh",
  })}
`;
const Title = styled.h1`
  font-size: 3em;
  > span {
    display: inline-block;
    color: orange;
    height: 1.25em;
    vertical-align: bottom;
    overflow: hidden;
    span {
      display: block;
      animation: ${AnimateWords} 10s infinite ease;
      width: 50vw;
      ${tablet({
        width: "100vw",
      })}

      ${mobile({
        textAlign: "center",
        minWidth: "80vw",
      })}
    }
  }
  ${tablet({
    fontSize: "2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    width: "100vw",
    textTransform: "uppercase",
    textAlign: "center",
  })}

  ${mobile({
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "20px",
    width: "100vw",
    textTransform: "uppercase",
  })}
`;
const Box = styled.div`
  display: flex;

  ${mobile({
    flexDirection: "column-reverse",
    gap: "0px",
  })}
`;
const LeftContainer = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
`;
const RightContainer = styled.div`
  flex: 1;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const Wrapper = styled.div`
  display: flex;
  ${mobile({
    width: "80vw",
    margin: "auto",
    gap: "30px",
    flexDirection: "column",
  })}
`;
const Slide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Head = styled.h4`
  letter-spacing: 1px;
  font-size: 20px;

  i {
    color: orange;
    margin-right: 10px;
  }

  ${mobile({
    fontSize: "18px",
  })}
`;
const Body = styled.p`
  color: white;
  line-height: 2;
  width: 80%;
  font-size: 17px;
  ${mobile({
    fontSize: "14px",
  })}
`;
const TopImage = styled.div`
  width: 80%;
  height: 200px;
  overflow: hidden;
  margin: 3rem;

  img {
    width: 100%;
    height: 100%;
    transition: 0.5s all ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "50%",
    height: "100px",
  })}
`;
const BottomImage = styled.div`
  width: 50%;
  height: 200px;
  position: absolute;
  top: 210px;
  left: 247px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    transition: 0.5s all ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    left: "100px",
    top: "130px",
    height: "100px",
    width: "50%",
  })}
`;
export default function Intro() {
  return (
    <>
      <Container>
        <Title>
          MaVen Technologies provide{" "}
          <span>
            <span>Turn Key Solution</span>
            <span>Automation Solution</span>
            <span>Jigs and Fixture Support</span>
            <span>Fabrication Support</span>
          </span>
        </Title>
        <Box>
          <LeftContainer>
            <TopImage>
              <img
                src="https://www.mallesautomation.com/wp-content/images/1.jpg"
                alt="legend1"
              ></img>
            </TopImage>
            <BottomImage>
              <img
                src="https://www.mallesautomation.com/wp-content/images/aboutus-home.jpg"
                alt="legend2"
              ></img>
            </BottomImage>
          </LeftContainer>
          <RightContainer>
            <Wrapper>
              <Slide>
                <Head>
                  <i className="fa-solid fa-angle-right"></i>VISION
                </Head>
                <Body>
                  To be a world-class organization in our products, processes
                  and performance while transcending boundaries and delivering
                  sustainable solutions.
                </Body>
              </Slide>
              <Slide>
                <Head>
                  <i className="fa-solid fa-angle-right"></i>MISSION
                </Head>
                <Body>
                  To relentlessly endeavour to be the preferred choice across
                  the construction, hospitality and automation markets, without
                  compromising on our core values and ambition to diversify
                  further.
                </Body>
              </Slide>
            </Wrapper>
          </RightContainer>
        </Box>
      </Container>
    </>
  );
}
