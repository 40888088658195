import Hero from "../components/Hero";
import Service from "../components/services/Service";
import Footer from "../components/Footer";

export default function Services() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/02/54/88/82/240_F_254888225_TrChEKZv4F5BgYrQGfEWXY7KUQbXgrIz.jpg"
        title="Services"
      />
      <Service />
      <Footer />
    </>
  );
}
