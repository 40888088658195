import Footer from "../components/Footer";
import Services from "../components/home/Services";
import Slider from "../components/home/Slider";
import Intro from "../components/home/Intro";
import Industries from "../components/home/Industries";

export default function Home() {
  return (
    <>
      <Slider />
      <Intro />
      <Services />
      <Industries />
      <Footer />
    </>
  );
}
