import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import MaterialHandling from "./components/application/MaterialHandling";
import Assembly from "./components/application/Assembly";
import Sealing from "./components/application/Sealing";
import Palletizing from "./components/application/Palletizing";
import Welding from "./components/application/Welding";
import Painting from "./components/application/Painting";
import MachineTending from "./components/application/MachineTending";
import Deburring from "./components/application/Deburring";
import Milling from "./components/application/Milling";
import Polishing from "./components/application/Polishing";
import Grinding from "./components/application/Grinding";
import Industries from "./pages/Industries";
import RoboticAutomation from "./components/systems/RoboticAutomation";
import Conveyors from "./components/systems/Conveyors";
import SpecialPurposeMachines from "./components/systems/SpecialPurposeMachines";
import WarehouseAutomation from "./components/systems/WarehouseAutomation";
import About from "./pages/About";
import Fixtures from "./pages/Fixtures";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/material-handling" element={<MaterialHandling />}></Route>
        <Route path="/assembly" element={<Assembly />}></Route>
        <Route path="/sealing" element={<Sealing />}></Route>
        <Route path="/palletizing" element={<Palletizing />}></Route>
        <Route path="/welding" element={<Welding />}></Route>
        <Route path="/painting" element={<Painting />}></Route>
        <Route path="/machine-tending" element={<MachineTending />}></Route>
        <Route path="/deburring" element={<Deburring />}></Route>
        <Route path="/milling" element={<Milling />}></Route>
        <Route path="/polishing" element={<Polishing />}></Route>
        <Route path="/grinding" element={<Grinding />}></Route>
        <Route path="/industries" element={<Industries />}></Route>
        <Route path="/robotic-automation" element={<RoboticAutomation />}></Route>
        <Route path="/conveyors" element={<Conveyors />}></Route>
        <Route path="/special-purpose-machines" element={<SpecialPurposeMachines />}></Route>
        <Route path="/warehouse-automation" element={<WarehouseAutomation />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/fixtures" element={<Fixtures />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
    </>
  );
}

export default App;
