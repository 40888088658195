import styled from "styled-components";
import Navbar from "./Navbar";
import { mobile } from "../responsive";

const Container = styled.div`
  height: 80vh;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)),
    url(${(props) => props.bgimage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${mobile({
    height: "50vh",
    backgroundSize: "contain-box",
  })}
`;
const Title = styled.h1`
  font-size: 54px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  letter-spacing: 2px;
  text-shadow: 2px 2px black;

  ${mobile({
    fontSize: "20px",
    textTransform: "uppercase",
    letterSpacing: "3px",
    textAlign: "center",
    lineHeight: "1.5",
  })}
`;

export default function Hero({ bg, title }) {
  return (
    <>
      <Container bgimage={bg}>
        <Navbar />
        <Title>{title}</Title>
      </Container>
    </>
  );
}
