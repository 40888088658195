import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    margin: "10px 0px",
  })}
`;
const Slide = styled.div`
  height: 80vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  min-height: 80vh;
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 45px;
  width: 150px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: grey;
  &:hover {
    color: orange;
  }
`;
export default function Painting() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/02/83/49/64/240_F_283496421_MGn8b1JyH4pOQDB8GusWtBTC9CfVyq7B.jpg"
        title="Painting"
      />
      <Container>
        <Slide>
          <Title>Control levels and quality beyond expectations</Title>
          <Info>
            Specialized equipment is required in order to ensure consistent and
            perfect paint finish for automated paint applications. This includes
            an explosion-proof robot arm, spray applicator/bell, gear pumps,
            colour change manifolds, solenoid valves, transducers and pressure
            regulator. A variety of technologies are provided to solve the
            challenges associated with achieving consistently premium paint
            finish quality and we offer a new line of robot arms in various
            sizes and configurations to meet any robotic painting application.
            We offer a new line of robot arms in various sizes with best of
            configurations that can manage even complicated applications.
          </Info>
          <Image>
            <img
              src="https://t3.ftcdn.net/jpg/04/28/68/56/240_F_428685654_Yxg785TX4OdAzXFea0cjJlDLo5GcocoD.jpg"
              alt="painting"
            />
          </Image>
        </Slide>
        <Palette>
          <Heading>Applications</Heading>
          <List>
            <ListItem>
              <Nav to="/material-handling">Material Handling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/assembly">Assembly</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/sealing">Sealing/Dispensing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/palletizing">Palletizing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/welding">Welding</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/painting">Painting</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/machine-tending">Machine Tending</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/deburring">Deburring</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/milling">Milling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/polishing">Polishing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/grinding">Grinding</Nav>
            </ListItem>
          </List>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
