import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../../responsive";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("https://images.pexels.com/photos/585418/pexels-photo-585418.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Title = styled.h1`
  text-align: center;
  font-size: 54px;
  color: white;
  margin-top: 1rem;
  letter-spacing: 1px;

  ${mobile({
    fontSize: "30px",
    textTransform: "uppercase",
    marginTop: "30px",
  })}
`;
const Wrapper = styled.div`
  width: 80vw;
  min-height: 80vh;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  padding: 20px;

  ${mobile({
    gap: "30px",
  })}
`;
const Slide = styled.div`
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  background: aliceblue;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: white;
  padding: 0 1rem;
  position: relative;
  text-shadow: 1px 1px black;

  p {
    width: 100%;
    height: 0%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), #6a062f);
    position: absolute;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    text-align: center;
    font-size: 16px;
    transition: height 0.5s;

    ${mobile({
      fontSize: "14px",
    })}
  }

  &:hover {
    p {
      height: 100%;

      ${mobile({
        textTransform: "none",
        letterSpacing: "0px",
      })}
    }
  }

  ${mobile({
    minHeight: "200px",
    fontSize: "20px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  })}
`;
const Button = styled(Link)`
  color: white;
  margin-top: 10px;
`;
export default function Industries() {
  const background = [
    {
      url: "https://t4.ftcdn.net/jpg/02/00/54/93/240_F_200549352_wxyBRnlMU4jGS0hcwkbYIZBwL5tNwcIj.jpg",
    },
    {
      url: "https://t3.ftcdn.net/jpg/00/14/24/06/240_F_14240675_Mng8uhGaGFblCEOTFc4IFVsUuEs5lCdy.jpg",
    },
    {
      url: "https://t3.ftcdn.net/jpg/03/05/50/70/240_F_305507033_BN534jYwkZk1gYGx9n03zfnoTkIDJTYL.jpg",
    },
    {
      url: "https://t4.ftcdn.net/jpg/01/40/41/87/240_F_140418746_9WuL4jNvKVwoZUOkokEodqHEgVzZyXoy.jpg",
    },
    {
      url: "https://t4.ftcdn.net/jpg/03/21/03/19/240_F_321031954_RX4bH9saYe4nZvhrzjc0Jxci1bxxPkzh.jpg",
    },
    {
      url: "https://t3.ftcdn.net/jpg/02/72/78/64/240_F_272786407_a6PZAQrzPMA8bwJyEWvMygiqqgTupaDG.jpg",
    },
  ];
  return (
    <>
      <Container>
        <Title>Industries</Title>
        <Wrapper>
          <Slide bg={background[4].url}>
            Engineering
            <p>
              The challenging applications in the wide spread engineering sector
              keeps us on our toes and alsd is a key factor to drive innovation
              and research in our business.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>

          <Slide bg={background[1].url}>
            Energy
            <p>
              Some applications, we provide assembly and testing solutions.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>
          <Slide bg={background[2].url}>
            Electronics
            <p>
              Accuracy and repeatability are the key terminologies in the ever
              growing electronics industry. We employ the same terminologies in
              the solutions we provide for our client in the industry giving
              them a competitive edge in the market.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>
          <Slide bg={background[3].url}>
            Life Science
            <p>
              As human kind benefits from the rapidly advancing medical and
              healthcare sector, we take pride in associating with the sector.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>
          <Slide bg={background[0].url}>
            Transportation
            <p>
              Growing urbanization leads to an increasing demand for transport
              and mobility, which requires a corresponding increase in their
              efficiency of operation.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>
          <Slide bg={background[5].url}>
            Consumer Packaged Goods
            <p>
              Consumer Packaged Goods have challenges to meet the changing
              demands of the consumers, maneuvering through a consolidating
              market, and executing strategies to grow profitably.
              <Button to="/industries">Read more</Button>
            </p>
          </Slide>
        </Wrapper>
      </Container>
    </>
  );
}
