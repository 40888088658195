import { Link } from "react-router-dom";
import styled from "styled-components";
import { iphone, mobile } from "../responsive";

const Container = styled.div`
  min-height: 90vh;
  width: 100vw;
  background: #01668f;
  padding: 5rem 10rem;
  display: flex;
  overflow-x: hidden;

  ${mobile({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
  })}
`;
const Box = styled.div`
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "60vw",
    alignItems: "start",
    justifyContent: "center",
  })}
`;
const Title = styled.h3`
  font-size: 26px;

  ${mobile({
    fontSize: "25px",
  })}
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  color: #e4e7ff;
  font-size: 16px;
  gap: 20px;

  i {
    color: orange;
    font-size: 20px;
  }
  p {
    width: 80%;
    line-height: 1.5;
    cursor: pointer;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &:hover a {
    color: orange;
  }

  ${mobile({
    fontSize: "14px",
  })}
`;
// const SocialContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   margin: 20px 0;
// `;
// const Icon = styled.i`
//   height: 50px;
//   width: 50px;
//   background: #121b2b;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 20px;
//   transition: 0.4s all ease-in-out;

//   &:hover {
//     background: orange;
//     cursor: pointer;
//   }
//   &:hover i {
//     transform: scale(1.2);
//   }
// `;
const List = styled.ul``;
const Item = styled.li`
  color: #e4e7ff;
  list-style-type: none;
  padding: 10px 0px;
  font-size: 16px;
  line-height: 1.3;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
    color: orange;
  }
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: white;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: orange;
  }
`;
const Signature = styled.div`
  background: #01668f;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s all ease-in;

  p {
    width: 90vw;
    height: 7vh;
    padding: 10px;
    font-size: 18px;
    color: white;
    border-top: 1px solid white;

    ${iphone({
      height: "10vh",
    })}

    ${mobile({
      fontSize: "14px",
      lineHeight: "1.5",
    })}
  }

  span {
    color: orange;
    font-weight: 500;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: blue;
    text-decoration: underline;
  }
`;

export default function Footer() {
  return (
    <>
      <Container>
        <Box>
          <Title>Contact</Title>
          <Info>
            <i className="fa-solid fa-location-dot"></i>
            <p>
              <a
                href="https://goo.gl/maps/Wtxb3fVzgCrzrvmLA"
                target="_blank"
                rel="noopener noreferrer"
              >
                No-36/19 Pillayar Koil 4th Street Ekkattuthangal-600032
              </a>
            </p>
          </Info>
          <Info>
            <i className="fa-solid fa-envelope"></i>
            <p>
              <a
                href="mailto: maventechnologies55@gmail.com"
                rel="noopener noreferrer"
              >
                admin@maven.com
              </a>
            </p>
          </Info>
          <Info>
            <i className="fa-solid fa-phone-volume"></i>
            <p>
              <a href="tel: +919940783337" rel="noopener noreferrer">
                99407 83337
              </a>
            </p>
          </Info>
          {/* <SocialContainer>
            <Icon>
              <i className="fa-brands fa-twitter"></i>
            </Icon>
            <Icon>
              <i className="fa-brands fa-facebook"></i>
            </Icon>
            <Icon>
              <i className="fa-brands fa-linkedin"></i>
            </Icon>
            <Icon>
              <i className="fa-brands fa-youtube"></i>
            </Icon>
          </SocialContainer> */}
        </Box>
        <Box>
          <Title>Application</Title>
          <List>
            <Item>
              <Nav to="/material-handling">Material Handling</Nav>
            </Item>
            <Item>
              <Nav to="/assembly">Assembly</Nav>
            </Item>
            <Item>
              <Nav to="/sealing">Sealing/Dispensing</Nav>
            </Item>
            <Item>
              <Nav to="/palletizing">Palletizing</Nav>
            </Item>
            <Item>
              <Nav to="/welding">Welding</Nav>
            </Item>
            <Item>
              <Nav to="/painting">Painting</Nav>
            </Item>
            <Item>
              <Nav to="/machine-tending">Machine Tending</Nav>
            </Item>
            <Item>
              <Nav to="/deburring">Deburring</Nav>
            </Item>
            <Item>
              <Nav to="/milling">Milling</Nav>
            </Item>
            <Item>
              <Nav to="/polishing">Polishing</Nav>
            </Item>
            <Item>
              <Nav to="/grinding">Grinding</Nav>
            </Item>
          </List>
        </Box>
        <Box>
          <Title>Pages</Title>
          <List>
            <Item>
              <Nav to="/">Home</Nav>
            </Item>
            <Item>
              <Nav to="/industries">Industries</Nav>
            </Item>
            <Item>
              <Nav to="/material-handling">Application</Nav>
            </Item>
            <Item>
              <Nav to="/robotic-automation">Systems</Nav>
            </Item>
            <Item>
              <Nav to="/services">Services</Nav>
            </Item>
            <Item>
              <Nav to="/about-us">About Us</Nav>
            </Item>
            <Item>
              <Nav to="/contact">Contact Us</Nav>
            </Item>
          </List>
        </Box>
      </Container>
      <Signature>
        <p>
          @2023 <span>MaVen Technologies</span> | All Rights Reserved.
          Maintained by{" "}
          <a
            href="https://www.interdeccaan.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inter Deccaan Solutions
          </a>
        </p>
      </Signature>
    </>
  );
}
