import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo-no-bg.png";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
  position: absolute;
  z-index: 2000;
  display: flex;
  width: 100vw;
  min-height: 10vh;
  justify-content: center;
  color: white;
  margin-top: 1rem;

  ${tablet({
    flexDirection: "column",
    minHeight: "15vh",
  })}

  ${mobile({
    flexDirection: "column",
    height: "20vh",
  })}
`;
const Name = styled.h2`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${mobile({
    padding: "0px 10px",
  })}
`;
const List = styled.ul`
  flex: 3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const ListItem = styled.li`
  list-style-type: none;
  cursor: pointer;
  color: #c9d4ff;
  font-size: 17px;
  letter-spacing: 1px;
  position: relative;
  transition: 0s all ease-in-out;
  height: ${(props) => props.height};
  padding-top: ${(props) => props.pad};

  ul {
    display: block;
    position: absolute;
    top: 60px;
    text-decoration: none;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 8px;
      background: white;
      color: grey;
      height: 50px;
      width: 180px;
      border-bottom: 1px solid lightgrey;
      margin: 0 2px;
      transition: 0.1s all ease-in-out;

      &:hover {
        color: orange;
      }
    }
  }

  ${mobile({
    display: "none",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: grey;
  &:hover {
    color: orange;
  }
`;
const NavLink = styled(Link)`
  text-decoration: none;
  color: #c9d4ff;
  &:hover {
    color: #ffffff;
  }
`;
const Icon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain-box;
    mix-blend-mode: color-burn;
  }

  ${mobile({
    width: "50px",
    height: "50px",
  })}
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Poppins", sans-serif;
`;
const Title = styled.h1`
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 200px;
  text-shadow: 2px 2px black;

  ${mobile({
    fontSize: "25px",
  })}
`;
const Slogan = styled.p`
  font-size: 16px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 1px 1px black;

  ${mobile({
    fontSize: "10px",
    textShadow: "none",
  })}
`;
const Bar = styled.div`
  display: none;

  li {
    display: none;
  }

  ${mobile({
    display: "flex",
    height: "10vh",
    wdith: "100vw",
    alignItems: "center",
    justifyContent: "space-around",
  })}
`;
const Input = styled.div`
  ${mobile({
    width: "70vw",
    height: "5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "black",
    background: "white",
    boxShadow: "1px 1px 1px 0px black",
  })}

  input {
    ${mobile({
      fontSize: "16px",
      padding: "5px",
      outline: "none",
      border: "none",
    })}
  }
`;
const Burger = styled.i`
  ${mobile({
    cursor: "pointer",
    fontSize: "30px",
    transition: ".2s all ease-in",
    textShadow: "1px 1px black",
  })}
`;
const Menu = styled.div`
  display: none;
  top: ${(props) => (props.menuValue ? "-20px" : "-100vh")};
  transition: 0.3s all ease-in-out;

  ${mobile({
    background: "black",
    display: "flex",
    flexDirection: "column",
    height: "60vh",
    width: "85vw",
    position: "absolute",
    left: "0px",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  })}

  div {
    color: white;
    font-size: 20px;
    height: 7vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
  }
`;
const DataResult = styled.div`
  position: absolute;
  top: 120px;
  left: 25px;
  min-height: 5vh;
  width: 70vw;
  background: black;
  display: flex;
  flex-direction: column;
`;
const Item = styled(Link)`
  color: white;
  font-size: 16px;
  text-decoration: none;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: orange;
  }
`;

export default function Navbar() {
  const [dropDown, setDropDown] = useState(Array(2).fill(false));
  const handleMouseEnter = (i) => {
    const nextDropDown = dropDown.slice();
    nextDropDown[i] = true;
    setDropDown(nextDropDown);
  };
  const handleMouseLeave = (i) => {
    const nextDropDown = dropDown.slice();
    nextDropDown[i] = false;
    setDropDown(nextDropDown);
  };

  const [menu, setMenu] = useState(false);
  const onHandleMenu = () => {
    setMenu(!menu);
  };

  const [wordEntered, setWordEntered] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleFilter = (e) => {
    const searchWord = e.target.value;
    setWordEntered(searchWord);
    const newFilter = path.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") setFilteredData([]);
    else setFilteredData(newFilter);
  };

  const handleClear = () => {
    setWordEntered("");
    setFilteredData([]);
  };
  return (
    <>
      <Container>
        <Name>
          <Icon>
            <img src={logo} alt="logo" />
          </Icon>
          <Text>
            <Title>MaVen Technologies</Title>
            <Slogan>Accelerate your outcomes</Slogan>
          </Text>
        </Name>
        <Bar>
          <Input>
            <input
              type="text"
              placeholder="Search services/products..."
              id="search"
              value={wordEntered}
              onChange={handleFilter}
            />
            {filteredData.length !== 0 && wordEntered.length !== 0 ? (
              <i className="fa-solid fa-xmark" onClick={handleClear}></i>
            ) : (
              <i class="fa-solid fa-magnifying-glass"></i>
            )}

            {filteredData.length !== 0 && (
              <DataResult>
                {filteredData.map((value) => {
                  return <Item to={value.url}>{value.title}</Item>;
                })}
              </DataResult>
            )}
          </Input>
          <Burger onClick={onHandleMenu}>
            {menu ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <i class="fa-solid fa-bars"></i>
            )}
          </Burger>
        </Bar>
        <Menu menuValue={menu}>
          <div>
            <NavLink to="/">Home</NavLink>
          </div>
          <div>
            <NavLink to="/industries">Industries</NavLink>
          </div>
          <div>
            <NavLink to="/material-handling">Applications</NavLink>
          </div>
          <div>
            <NavLink to="/robotic-automation">Systems</NavLink>
          </div>
          <div>
            <NavLink to="/services">Services</NavLink>
          </div>
          <div>
            <NavLink to="/about-us">About Us</NavLink>
          </div>
          <div>
            <NavLink to="/contact">Contact Us</NavLink>
          </div>
        </Menu>
        <List>
          <ListItem>
            <NavLink to="/">Home</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/industries">Industries</NavLink>
          </ListItem>
          <ListItem
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
            height={dropDown[0] ? "10vh" : ""}
            pad={dropDown[0] ? "30px" : ""}
          >
            Application
            {dropDown[0] ? (
              <ul>
                <li>
                  <Nav to="/material-handling">Material Handling</Nav>
                </li>
                <li>
                  <Nav to="/assembly">Assembly</Nav>
                </li>
                <li>
                  <Nav to="/sealing">Sealing/Dispensing</Nav>
                </li>
                <li>
                  <Nav to="/palletizing">Palletizing</Nav>
                </li>
                <li>
                  <Nav to="/welding">Welding</Nav>
                </li>
                <li>
                  <Nav to="/painting">Painting</Nav>
                </li>
                <li>
                  <Nav to="/machine-tending">Machine Tending</Nav>
                </li>
                <li>
                  <Nav to="/deburring">Deburring</Nav>
                </li>
                <li>
                  <Nav to="/milling">Milling</Nav>
                </li>
                <li>
                  <Nav to="/polishing">Polishing</Nav>
                </li>
                <li>
                  <Nav to="/grinding">Grinding</Nav>
                </li>
              </ul>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
            height={dropDown[1] ? "10vh" : ""}
            pad={dropDown[1] ? "30px" : ""}
          >
            Systems
            {dropDown[1] ? (
              <ul>
                <li>
                  <Nav to="/robotic-automation">Robotic Automation</Nav>
                </li>
                <li>
                  <Nav to="/conveyors">Conveyors</Nav>
                </li>
                <li>
                  <Nav to="/special-purpose-machines">
                    Special Purpose Machines
                  </Nav>
                </li>
                <li>
                  <Nav to="/warehouse-automation">Warehouse Automation</Nav>
                </li>
              </ul>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem>
            <NavLink to="/services">Services</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/about-us">About Us</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/contact">Contact Us</NavLink>
          </ListItem>
        </List>
      </Container>
    </>
  );
}

const path = [
  {
    title: "Material Handling",
    url: "/material-handling",
  },
  {
    title: "Assembly",
    url: "/assembly",
  },
  {
    title: "Sealing",
    url: "/sealing",
  },
  {
    title: "Palletizing",
    url: "/palletizing",
  },
  {
    title: "Welding",
    url: "/welding",
  },
  {
    title: "Painting",
    url: "/painting",
  },
  {
    title: "Machine Tending",
    url: "/machine-tending",
  },
  {
    title: "Deburring",
    url: "/deburring",
  },
  {
    title: "Milling",
    url: "/milling",
  },
  {
    title: "Polishing",
    url: "/polishing",
  },
  {
    title: "Grinding",
    url: "/grinding",
  },
  {
    title: "Robotic Automation",
    url: "/robotic-automation",
  },
  {
    title: "Conveyors",
    url: "/conveyors",
  },
  {
    title: "Special Purpose Machines",
    url: "/special-purpose-machines",
  },
  {
    title: "Warehouse Automation",
    url: "/warehouse-automation",
  },
  {
    title: "Fixtures",
    url: "/fixtures"
  }
];
