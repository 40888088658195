import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    margin: "10px 0px",
  })}
`;
const Slide = styled.div`
  min-height: 60vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  min-height: 80vh;
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 45px;
  width: 150px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: grey;
  &:hover {
    color: orange;
  }
`;
export default function Grinding() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/02/69/98/88/240_F_269988834_cbVwHYAbq3oknblePwxmKy2kjYitiCHA.jpg"
        title="Grinding"
      />
      <Container>
        <Slide>
          <Title>
            Automated grinding systems to minimise cost and improve safety
          </Title>
          <Info>
            Grinding is the process of removing excess material from the surface
            of machined parts and products. A robotic grinder can save the
            manufacturers time and production costs while improving the
            integrity of each grinded part’s uniformity. Also, manual grinding
            is tough and messy work that can cause injuries and health hazard to
            workers. Robotic grinding can ensure the safety of workers and
            prevent workers from the dust, noise and from enduring the hazardous
            environment.
          </Info>
          <Image>
            <img
              src="https://t3.ftcdn.net/jpg/05/85/87/40/240_F_585874036_3tvYqFRF2nWA54JkTanMWUfyS8ANqtrZ.jpg"
              alt="grinding"
            />
          </Image>
        </Slide>
        <Palette>
          <Heading>Applications</Heading>
          <List>
            <ListItem>
              <Nav to="/material-handling">Material Handling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/assembly">Assembly</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/sealing">Sealing/Dispensing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/palletizing">Palletizing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/welding">Welding</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/painting">Painting</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/machine-tending">Machine Tending</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/deburring">Deburring</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/milling">Milling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/polishing">Polishing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/grinding">Grinding</Nav>
            </ListItem>
          </List>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
