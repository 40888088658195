import styled from "styled-components";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import { mobile } from "../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    gap: "0px",
    padding: "20px 0px",
  })}
`;
const Slide = styled.div`
  min-height: 60vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  height: 90%;
  width: 30vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "0px",
  })}
`;
const Navigator = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;
const Nav = styled(Link)`
  text-decoration: none;
  font-size: 18px;
  color: grey;
  color: ${(props) => props.color};
  &:hover {
    color: orange;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Help = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: black;
  color: white;

  ${mobile({
    width: "90vw",
  })}
`;
const HelpInfo = styled.p`
  line-height: 1.5;

  ${mobile({
    fontSize: "16px",
  })}
`;

export default function Fixtures() {
  const [select, setSelect] = useState([true, false, false]);
  const handleClick = (i) => {
    let newArr = Array(3).fill(false);
    newArr[i] = true;
    setSelect(newArr);
  };

  return (
    <>
      <Hero
        bg="https://t4.ftcdn.net/jpg/06/13/36/37/240_F_613363701_LfdtR43L5wfWXdbU8If7WcBj8a30JlYu.jpg"
        title="Fixtures"
      />
      <Container>
        {select[0] ? (
          <Slide>
            <Title>
              Precision welding fixtures: Elevating your welding precision to
              new heights
            </Title>
            <Info>
              With our cutting-edge expertise and state-of-the-art facilities,
              we specialize in designing and producing high-quality fixtures
              tailored to your specific welding needs. Our team of experienced
              engineers combines advanced technology with meticulous
              craftsmanship to deliver fixtures that ensure optimal alignment,
              stability, and accuracy, enabling you to achieve exceptional
              welding results. From concept to completion, we work closely with
              you to understand your unique requirements and deliver custom-made
              fixtures that enhance your productivity, efficiency, and overall
              welding process. Trust Maven Technology for superior welding
              fixtures that elevate your precision and performance to new
              heights.
            </Info>
            <Image>
              <img
                src="https://t3.ftcdn.net/jpg/05/53/57/50/240_F_553575098_tUlPbLlleIljG8TanllcxZpakaYDM1oP.jpg"
                alt="welding fixture"
              />
            </Image>
          </Slide>
        ) : (
          ""
        )}
        {select[1] ? (
          <Slide>
            <Title>
              Precision Assemble Fixtures: Perfecting Your Assembly Process with
              Expertise
            </Title>
            <Info>
              With our cutting-edge expertise and state-of-the-art facilities,
              we specialize in crafting bespoke fixtures tailored to meet your
              unique manufacturing needs. Our skilled engineers work closely
              with you to understand your requirements and deliver innovative
              solutions that optimize efficiency, accuracy, and productivity.
              Whether you need precision welding fixtures, modular assembly
              fixtures, or custom-designed solutions, we ensure exceptional
              craftsmanship and attention to detail in every project. Trust in
              our commitment to excellence, reliability, and on-time delivery as
              we help you streamline your production process and elevate your
              manufacturing capabilities. Experience the Maven difference today.
            </Info>
            <Image>
              <img
                src="https://t3.ftcdn.net/jpg/02/57/06/16/240_F_257061628_Uo3wfAev3EVaKPgmJbfJVjRQmvSOfWMz.jpg"
                alt="assemble fixture"
              />
            </Image>
          </Slide>
        ) : (
          ""
        )}
        {select[2] ? (
          <Slide>
            <Title>
              Streamline your business with MaVen Technology's seamless and
              reliable checking support solutions
            </Title>
            <Info>
              With our extensive expertise and state-of-the-art facilities, we
              specialize in the design, development, and production of precision
              checking fixtures. Our dedicated team of skilled engineers and
              technicians works closely with clients to understand their
              specific requirements, ensuring the creation of custom-made
              solutions that guarantee exceptional accuracy and reliability.
              From initial concept to final product, we prioritize quality,
              efficiency, and customer satisfaction at every stage. Whether
              you're in automotive, aerospace, or any other industry, rely on
              MaVen Technology for advanced checking fixtures that optimize your
              production processes and elevate your manufacturing standards.
            </Info>
            <Image>
              <img
                src="https://t4.ftcdn.net/jpg/05/64/23/33/240_F_564233369_nSgD7pSZD79mxgTnOQv2yLmNecTF20YL.jpg"
                alt="checking fixture"
              />
            </Image>
          </Slide>
        ) : (
          ""
        )}
        <Palette>
          <Navigator>
            <Heading>Fixtures</Heading>
            <List>
              <ListItem onClick={() => handleClick(0)}>
                Welding Fixture
              </ListItem>
              <ListItem onClick={() => handleClick(1)}>
                Assemble Fixture
              </ListItem>
              <ListItem onClick={() => handleClick(2)}>
                Checking Fixture
              </ListItem>
            </List>
          </Navigator>
          <Help>
            <Heading>Call now Or write a message</Heading>
            <HelpInfo>
              Please feel free to contact us with the simple Enquiry form on the
              below link with all mandatory details and our representatives will
              call you back shortly.
            </HelpInfo>
            <Nav to="/contact" color="white">
              Click here
            </Nav>
          </Help>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
