import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import { mobile } from "../../responsive";

const Title = styled.h1`
  font-size: 54px;
  color: #3d3e8a;
  text-align: center;
  padding-top: 4rem;

  ${mobile({
    fontSize: "30px",
    textTransform: "uppercase",
  })}
`;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f7f7f9;
`;
const Wrapper = styled.div`
  width: 80vw;
  margin: 5rem auto;
`;
const Slide = styled.div`
  height: 65vh;
  width: 350px;
  background: white;
`;
const Top = styled.div`
  img {
    height: 150px;
    width: 100%;
  }
`;
const Bottom = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: space-evenly;

  ${mobile({
    gap: "10px",
  })}
`;
const Head = styled.h3`
  transition: 0.2s all ease-in-out;
  &:hover {
    color: orange;
    cursor: pointer;
  }

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  width: 90%;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;

export default function Competencies() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Container>
        <Title>Competencies</Title>
        <Wrapper>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            showDots={true}
          >
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/00/38/62/06/240_F_38620610_v2uV1ymrkON02JWMpSgPbW9bei9wLjE7.jpg"
                    alt="body welding solution"
                  />
                </Top>
                <Bottom>
                  <Head>Body Welding Solutions</Head>
                  <Info>
                    Experience excellence in body welding solutions with MaVen
                    Technologies. Our cutting-edge innovations and expertise
                    deliver precision, durability, and efficiency, ensuring
                    seamless integration into your manufacturing process. Trust
                    us to elevate your production capabilities to new heights.
                  </Info>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/06/04/60/05/240_F_604600591_sgTzaidqaAQvvQZsGuodEKOmkGIPhp7n.jpg"
                    alt="engineering services"
                  />
                </Top>
                <Bottom>
                  <Head>Engineering Services</Head>
                  <Info>
                    We offer comprehensive and innovative engineering services
                    tailored to meet your specific needs, ensuring unparalleled
                    quality, precision, and efficiency in every project. Trust
                    us to bring your vision to life and redefine what's possible
                    in the world of technology.
                  </Info>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/02/52/63/72/240_F_252637253_4Xde45HExV7HJ1doyapQKqKR1zWLzvEt.jpg"
                    alt="prototype support"
                  />
                </Top>
                <Bottom>
                  <Head>Prototype Support</Head>
                  <Info>
                    Unlock your innovation potential with MaVen Technologies:
                    Your trusted partner for prototype support, turning ideas
                    into reality. Experience excellence in product development
                    with our cutting-edge solutions and expert guidance. Empower
                    your vision, redefine possibilities with MaVen Technologies.
                  </Info>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/05/90/63/19/240_F_590631991_KHEFCPTefqH0Ms348KXuIKSo3TqQVpLB.jpg"
                    alt="material handling"
                  />
                </Top>
                <Bottom>
                  <Head>Material Handling</Head>
                  <Info>
                    Transforming material handling with innovative solutions for
                    seamless efficiency and optimized productivity. Welcome to
                    MaVen Technologies, your trusted partner in streamlining
                    operations and unlocking new possibilities in the world of
                    material handling.
                  </Info>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/03/23/82/99/240_F_323829966_H32wLhoouiPinJ66KyggCvqQ2dFPuuQ1.jpg"
                    alt="innovation"
                  />
                </Top>
                <Bottom>
                  <Head>Innovation</Head>
                  <Info>
                    Embrace the future with MaVen Technologies, where innovation
                    thrives and possibilities unfold. Our cutting-edge solutions
                    pave the way for transformative advancements, empowering
                    businesses to conquer new horizons and redefine what's
                    possible.
                  </Info>
                </Bottom>
              </Slide>
            </div>
          </Carousel>
        </Wrapper>
      </Container>
    </>
  );
}
