import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    gap: "0px",
    padding: "20px 0px",
  })}
`;
const Slide = styled.div`
  min-height: 60vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  height: 90%;
  width: 30vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "0px",
  })}
`;
const Navigator = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;
const Nav = styled(Link)`
  text-decoration: none;
  font-size: 18px;
  color: grey;
  color: ${(props) => props.color};
  &:hover {
    color: orange;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Help = styled.div`
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: black;
  color: white;

  ${mobile({
    width: "90vw",
  })}
`;
const HelpInfo = styled.p`
  line-height: 1.5;

  ${mobile({
    fontSize: "16px",
  })}
`;
export default function SpecialPurposeMachines() {
  return (
    <>
      <Hero
        bg="https://t4.ftcdn.net/jpg/06/14/84/01/240_F_614840164_INlhbe28pjDV2sKqD1uVr3pAY7V6CXLV.jpg"
        title="Special Purpose Machines"
      />
      <Container>
        <Slide>
          <Title>Committed to value, committed to you</Title>
          <Info>
            Clients can avail from us, Special Purpose Machines (SPM) and
            Accessories that are used in many industrial applications. Our
            supplied machines are widely popular due to their durable nature and
            low maintenance. We offer SPM for Automobile Industries, Engineering
            ind. Agriculture ind. Pharma Ind. And we also offer welding SPMs.
            These SPMs can be automatic, semi-automatic and manual in nature.
          </Info>
          <Image>
            <img
              src="https://t3.ftcdn.net/jpg/01/11/05/10/240_F_111051064_3cx3duUE2atf220KRjV7AiVQ0QXkvx2p.jpg"
              alt="special-purpose-machines"
            />
          </Image>
        </Slide>
        <Palette>
          <Navigator>
            <Heading>Systems</Heading>
            <List>
              <ListItem>
                <Nav to="/robotic-automation">Robotic Automation</Nav>
              </ListItem>
              <ListItem>
                <Nav to="/conveyors">Conveyors</Nav>
              </ListItem>
              <ListItem>
                <Nav to="/special-purpose-machines">
                  Special Purpose Machines
                </Nav>
              </ListItem>
              <ListItem>
                <Nav to="/warehouse-automation">Warehouse Automation</Nav>
              </ListItem>
            </List>
          </Navigator>
          <Help>
            <Heading>Call now Or write a message</Heading>
            <HelpInfo>
              Please feel free to contact us with the simple Enquiry form on the
              below link with all mandatory details and our representatives will
              call you back shortly.
            </HelpInfo>
            <Nav to="/contact" color="white">
              Click here
            </Nav>
          </Help>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
