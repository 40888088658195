import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    margin: "10px 0px",
  })}
`;
const Slide = styled.div`
  height: 80vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  min-height: 80vh;
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 45px;
  width: 150px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: grey;
  &:hover {
    color: orange;
  }
`;
export default function Deburring() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/02/46/82/66/240_F_246826645_lu1c9WnOyIQ3jxGY0MA9Tare1yf9ZKpA.jpg"
        title="Deburring"
      />
      <Container>
        <Slide>
          <Title>
            Automated deburring solutions for enhanced product quality
          </Title>
          <Info>
            Automated deburring applications are used in major industries and
            include polishing, grinding, trimming, cutting, etc. The complex
            motion & positioning requirements, tool wear and the force control
            involved make the process challenging. We offer software options to
            solve many of the challenges that are associated with material
            removal applications. Feedback control to apply right amounts of
            force between the workpiece and the material removal equipment is
            also available.
          </Info>
          <Image>
            <img
              src="https://t4.ftcdn.net/jpg/06/17/01/51/240_F_617015117_93GRjeZkHzdaDOplW4XqZsughhbKgZQw.jpg"
              alt="machine tending"
            />
          </Image>
        </Slide>
        <Palette>
          <Heading>Applications</Heading>
          <List>
            <ListItem>
              <Nav to="/material-handling">Material Handling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/assembly">Assembly</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/sealing">Sealing/Dispensing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/palletizing">Palletizing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/welding">Welding</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/painting">Painting</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/machine-tending">Machine Tending</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/deburring">Deburring</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/milling">Milling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/polishing">Polishing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/grinding">Grinding</Nav>
            </ListItem>
          </List>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
