import styled from "styled-components";
import Hero from "../Hero";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: auto;
  display: flex;
  gap: 10px;

  ${mobile({
    flexDirection: "column",
    width: "100vw",
    margin: "10px 0px",
  })}
`;
const Slide = styled.div`
  height: 80vh;
  width: 50vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mobile({
    width: "100vw",
    padding: "40px",
  })}
`;
const Title = styled.h2`
  text-transform: uppercase;
  font-size: 34px;

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  line-height: 1.7;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Image = styled.div`
  height: 30vh;
  width: 30vw;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${mobile({
    width: "80vw",
    height: "25vh",
  })}
`;
const Palette = styled.div`
  min-height: 80vh;
  width: 25vw;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f3f0f3;

  ${mobile({
    width: "90vw",
    fontSize: "14px",
    minHeight: "40vh",
  })}
`;
const Heading = styled.h3`
  font-size: 24px;
  text-transform: uppercase;

  ${mobile({
    fontSize: "20px",
  })}
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  font-size: 18px;
  height: 45px;
  width: 150px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    cursor: pointer;
  }

  ${mobile({
    fontSize: "16px",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: grey;
  &:hover {
    color: orange;
  }
`;
export default function Palletizing() {
  return (
    <>
      <Hero
        bg="https://t3.ftcdn.net/jpg/05/99/63/02/240_F_599630268_k5ioDyHW2YCgENioYrCbLT3azK2aDL4L.jpg"
        title="Palletizing"
      />
      <Container>
        <Slide>
          <Title>Flexible solutions that save costs</Title>
          <Info>
            Palletizing is often the last step of the assembly process and
            involves stacking various items before being loaded for
            delivery/transportation. The process faces three main challenges –
            pattern flexibility, tooling flexibility and cycle time. These
            challenges can be tackled effectively using automated robotic
            systems. Our robots are specifically designed to work at high-speeds
            and handle heavy payloads. The robotics software is designed in a
            way that lets the user control patterns and status of procedures in
            an easy manner. Such robots can be used for palletizing procedures
            involving a wide range of materials such as bags, frozen food,
            cartons, etc.
          </Info>
          <Image>
            <img
              src="https://t4.ftcdn.net/jpg/06/04/04/01/240_F_604040143_4yUyCi46Ev8tBFtfKu54iXFQcdxIDk7k.jpg"
              alt="material-handling"
            />
          </Image>
        </Slide>
        <Palette>
          <Heading>Applications</Heading>
          <List>
            <ListItem>
              <Nav to="/material-handling">Material Handling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/assembly">Assembly</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/sealing">Sealing/Dispensing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/palletizing">Palletizing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/welding">Welding</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/painting">Painting</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/machine-tending">Machine Tending</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/deburring">Deburring</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/milling">Milling</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/polishing">Polishing</Nav>
            </ListItem>
            <ListItem>
              <Nav to="/grinding">Grinding</Nav>
            </ListItem>
          </List>
        </Palette>
      </Container>
      <Footer />
    </>
  );
}
