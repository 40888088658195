import styled from "styled-components";
import { useState } from "react";
import { mobile } from "../../responsive";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: auto;
  transition: 0.3s all linear;
  padding: 30px;
`;
const Item = styled.div`
  border: 1px solid lightgrey;
  min-height: 7vh;
  width: 70vw;
  min-height: ${(props) => props.height};

  ${mobile({
    width: "90vw",
  })}
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  height: 7vh;
  padding: 10px 30px;
  color: #44434f;
  align-items: center;
`;
const Title = styled.p`
  font-size: 27px;
  letter-spacing: 1px;

  ${mobile({
    fontSize: "20px",
    padding: "0px",
  })}
`;
const Plus = styled.button`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  font-weight: 100;
  background: white;
  border: none;
  cursor: pointer;
  color: #44434f;
`;
const Body = styled.div`
  padding: 10px 30px;
  line-height: 1.7;
  color: grey;
  font-size: 15px;

  ${mobile({
    fontSize: "14px",
  })}
`;

export default function Service() {
  const [show, setShow] = useState(Array(6).fill(false));
  const handleClick = (i) => {
    let nextShow = show.slice();
    for (let index = 0; index < 6; index++)
      if (index !== i) nextShow[index] = false;
    if (!nextShow[i]) nextShow[i] = true;
    else nextShow[i] = false;
    setShow(nextShow);
  };
  return (
    <>
      <Container height="true">
        <Item height={show[0] ? "90vh" : ""}>
          <Head>
            <Title>Programming</Title>
            <Plus onClick={() => handleClick(0)}>{show[0] ? "x" : "+"}</Plus>
          </Head>
          {show[0] ? (
            <Body>
              <p>
                At MaVen Technologies, we integrate robotics into any system by
                programming robotics software that controls the robotic device
                you need for your operation. MaVen Technologies can help your
                company with innovative, turnkey implementation of robot-based
                automated systems including design, supply, robot simulation,
                off-line and shop-floor programming of robots, robot cell
                calibration, training and documentation. As a part of our
                robotic programming and integration offerings, we make onsite
                visits to discuss about optimizing new or existing programming.
                Robotic programming from MaVen Technology permits the deployment
                of new robots in a cost-effective manner.
              </p>
              <br></br>
              <p>
                MaVen Technologies has experienced programming technicians to help
                you reduce your cycle time, or to improve the quality of
                existing paths or planned robotic automation. Industrial robot
                programming is necessary to control every task a robot is to
                perform. We are experts at programming robots to perform a wide
                variety of tasks such as material handling, machine tending,
                assembly, dispensing, welding, trimming applications, etc. Our
                multiple axis robotic automation allows the robot to position or
                orient its tool/device practically in any place that is
                mechanically feasible within its work envelope.
              </p>
              <br></br>
              <p>
                Our experience in robot programming enables our customers to
                maximize the productivity of their robotic automation. We also
                provide 24/7 availability for Robotic Programming Emergencies —
                Occurrences that may critically affect your productivity.
              </p>
              <br></br>
              <p>
                Contract flexibility — Extended term and short-term contracts
                for robotic automation and robot programming services are
                available.
              </p>
              <br></br>
              <p>
                Path and Source Code Robot Programming — Robotics systems can be
                programmed to reduce cycle time and increase throughput from
                both a path and custom source code standpoint.
              </p>
              <br></br>
              <p>
                Off-Line Robot Programming/Simulation — Our capable technicians
                can utilize off-line robotic programming and simulation software
                to reduce overall programming time and in-field debug. Software
                animation can be used to create programs and verify application
                feasibility for each robotic application.
              </p>
            </Body>
          ) : (
            ""
          )}
        </Item>
        <Item height={show[1] ? "40vh" : ""}>
          <Head>
            <Title>System Design</Title>
            <Plus onClick={() => handleClick(1)}>{show[1] ? "x" : "+"}</Plus>
          </Head>
          {show[1] ? (
            <Body>
              <p>
                Expertise and experience are the pillars of our robotic design
                services and engineering practices. When considering a system
                design specific to our customer’s requirements, process
                requirements, maintenance considerations, and reliability are
                key elements. Engineering and design team members at MaVen
                Technologies utilize high-end software to create and deliver the
                system design to the customer and manufacturing team ensuring
                compatibility with process and production goals. Designs are
                optimized for fit and function. At MaVen Technologies, our design
                experts help determine the robotics system design and component
                selection that is most beneficial for specific process and
                goals.
              </p>
              <br></br>
              <p>
                MaVen Technologies designs robotics design and applications that
                improve quality and production in difficult and challenging
                environments.
              </p>
            </Body>
          ) : (
            ""
          )}
        </Item>
        <Item height={show[2] ? "50vh" : ""}>
          <Head>
            <Title>Robot Design</Title>
            <Plus onClick={() => handleClick(2)}>{show[2] ? "x" : "+"}</Plus>
          </Head>
          {show[2] ? (
            <Body>
              <p>
                At MaVen Technologies, we offer integrated robot design for
                specific tasks and for specific automated systems.
              </p>
              <p>Our services include:</p>
              <ul style={{ marginLeft: "10px", lineHeight: "2" }}>
                <li>Gathering information</li>
                <li>
                  Identifying specific details of design which must be satisfied
                </li>
                <li>Identifying possible and alternative design solutions</li>
                <li>Planning and designing appropriate design structures</li>
                <li>
                  Creating a prototype
                  <ul type="circle" style={{ marginLeft: "30px" }}>
                    <li>testing the design </li>
                    <li>troubleshooting the design</li>
                  </ul>
                </li>
                <li>Building the robot</li>
              </ul>
            </Body>
          ) : (
            ""
          )}
        </Item>
        <Item height={show[3] ? "50vh" : ""}>
          <Head>
            <Title>Consultancy</Title>
            <Plus onClick={() => handleClick(3)}>{show[3] ? "x" : "+"}</Plus>
          </Head>
          {show[3] ? (
            <Body>
              <p>
                Our consultancy services address a wide range of unique
                problems. Robotics is a complex intersection of specialist
                fields, from mechanical to software. MaVen Technologies
                engineers bring together skills and experience from a broad
                range of areas needed to deliver bespoke solutions. The services
                we offer are tailored and shaped to help you get up and running
                with automation and robotics at a quick pace.
              </p>
              <br></br>
              <p>
                We have designed new products, created prototypes and delivered
                custom robots to clients in a range of sectors.
              </p>
              <br></br>
              <p>
                MaVen Technologies consulting services include end-to-end
                automation and robotic consulting services. Services include
                simultaneous engineering, design for testability, product
                debugging, testing and validation, manufacturing process
                strategy, process layout, planning and simulation, cost -
                benefit analysis, equipment specification writing, proof of
                principle and prototyping, engineering feasibility analysis,
                system designing, programming, building and integration,
                production line start-up, review and evaluations of existing
                production equipment, process flow and cycle time, optimization
                analysis, documentation of procedures and training and support.
              </p>
            </Body>
          ) : (
            ""
          )}
        </Item>
        <Item height={show[4] ? "80vh" : ""}>
          <Head>
            <Title>AMC</Title>
            <Plus onClick={() => handleClick(4)}>{show[4] ? "x" : "+"}</Plus>
          </Head>
          {show[4] ? (
            <Body>
              <p>
                In the fast-paced environment of various businesses, machine
                uptime is paramount to success and profitability. At MaVen
                Technologies, we recognize the importance and value of responsive
                service, automation training, robotics support and timely,
                turnkey solutions.
              </p>
              <br></br>
              <p>
                Our highly skilled cross-trained service team respond on a 24/7
                basis for service and support requirements ranging from factory
                automation, training and robotics to assembly systems. Whether
                the issue is mechanical, electrical, or programming, we provide
                service solutions to minimize downtime and boost efficiency. Our
                teams are equipped to handle immediate emergencies or routine
                maintenance on long-term service and support contracts.
              </p>
              <br></br>
              <p>
                Our highly skilled cross-trained service team respond on a 24/7
                basis for service and support requirements ranging from factory
                automation, training and robotics to assembly systems. Whether
                the issue is mechanical, electrical, or programming, we provide
                service solutions to minimize downtime and boost efficiency. Our
                teams are equipped to handle immediate emergencies or routine
                maintenance on long-term service and support contracts.
              </p>
              <br></br>
              <p>
                MaVen Technologies service technicians provide production system
                and robotics support. Our training includes minor robot
                programming, complete robotic integration or retrofit services
                for your automated robotics. Support, training, robotics
                automation and other services are available from the experts at
                MaVen Technology.
              </p>
              <br></br>
              <p>
                We provide operator training and automation training according
                to newly added equipment operators; or to re-educate current
                staff for new automation. Training can be customized to specific
                requirements and equipment, ensuring effectiveness and
                maximizing investment.
              </p>
            </Body>
          ) : (
            ""
          )}
        </Item>
        <Item height={show[5] ? "65vh" : ""}>
          <Head>
            <Title>Training</Title>
            <Plus onClick={() => handleClick(5)}>{show[5] ? "x" : "+"}</Plus>
          </Head>
          {show[5] ? (
            <Body>
              <p>
                We offer choice of in-house or on-site training from experienced
                trainers on various operations and maintenance procedures for
                robot-based automation systems.
              </p>
              <br></br>
              <p>
                Are you passionate about Technology and Innovations?? Yes, we
                Too..!!
              </p>
              <br></br>
              <p>
                MaVen Technologies provides trainings specifically designed for
                those who are passionate about future technology and innovations
                since it introduces you to the world of Robotics. Our aim is to
                provide face to face & Hands-On-Sessions to the candidates,
                which will help them to understand the theoretical as well as
                the practical aspects of the Robotics World.
              </p>
              <br></br>
              <p>
                The MaVen Technologies training workshops would help students to
                enter into the real world of Robotics which creates an aptitude
                in the limitless field of these emerging technologies. The
                workshop will guide them to get the feel of Robotics and how
                powerful & exciting it can be.
              </p>
              <br></br>
              <p>
                <b>MaVen Technologies offers 3 levels of training:</b>
                <ul style={{ marginLeft: "20px", lineHeight: "2" }}>
                  <li>Beginner Level</li>
                  <li>Intermediate Level</li>
                  <li>Expert Level</li>
                </ul>
              </p>
            </Body>
          ) : (
            ""
          )}
        </Item>
      </Container>
    </>
  );
}
