import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile } from "../../responsive";

const Title = styled.h1`
  font-size: 54px;
  color: #3d3e8a;
  text-align: center;
  padding-top: 4rem;

  ${mobile({
    fontSize: "30px",
    textTransform: "uppercase",
  })}
`;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f7f7f9;
`;
const Wrapper = styled.div`
  width: 80vw;
  margin: 5rem auto;
`;
const Slide = styled.div`
  min-height: 60vh;
  width: 350px;
  background: white;

  ${mobile({
    minHeight: "60vh",
  })}
`;
const Top = styled.div`
  img {
    height: 150px;
    width: 100%;

    ${mobile({
      height: "130px",
    })}
  }
`;
const Bottom = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: space-evenly;
`;
const Head = styled.h3`
  transition: 0.2s all ease-in-out;
  &:hover {
    color: orange;
    cursor: pointer;
  }

  ${mobile({
    fontSize: "20px",
  })}
`;
const Info = styled.p`
  color: grey;
  width: 90%;
  line-height: 1.7;
  
  ${mobile({
    fontSize: "14px",
  })}
`;
const Button = styled.button`
  border: none;
  background: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: start;

  ${mobile({
    fontSize: "14px",
  })}
`;
const Nav = styled(Link)`
  text-decoration: none;
  color: tomato;
`;

export default function Services() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Container>
        <Title>Services</Title>
        <Wrapper>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            showDots={true}
          >
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/03/21/03/19/240_F_321031954_RX4bH9saYe4nZvhrzjc0Jxci1bxxPkzh.jpg"
                    alt="AMC"
                  />
                </Top>
                <Bottom>
                  <Head>AMC</Head>
                  <Info>
                    We provide service solutions to minimize downtime and boost
                    efficiency. Our teams are equipped to handle immediate
                    maintenance on long-term service and support contracts.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/06/05/56/66/240_F_605566647_8EO4mkj7bG6BcvjoJEn8RxH2oGAa1xJX.jpg"
                    alt="fixtures"
                  />
                </Top>
                <Bottom>
                  <Head>Fixtures</Head>
                  <Info>
                    Unlocking seamless success through unmatched fixture
                    support, Maven Technology empowers your business with
                    unrivaled expertise and unwavering commitment.
                  </Info>
                  <Button>
                    <Nav to="/fixtures">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/02/13/59/40/240_F_213594025_gAzvAKRuB2uYrfBc88HhwSZBb1M4eWWA.jpg"
                    alt="Traning"
                  />
                </Top>
                <Bottom>
                  <Head>Training</Head>
                  <Info>
                    We offer choice of in-house or on-site training from
                    experienced trainers on various operations and maintenance
                    procedures for robot-based automation systems.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/04/90/33/39/240_F_490333979_EQdf0tNa0W7MdstKjrGwnjQjoxfYP2zO.jpg"
                    alt="Programming"
                  />
                </Top>
                <Bottom>
                  <Head>Programming</Head>
                  <Info>
                    We offer programming services for highly-customized and
                    high-performing robots. Our optimized programming results in
                    precise and wastage-free robots.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/06/09/82/34/240_F_609823410_AAbTjClRx0qDB66A1zrAdH3OjbvjKuMZ.jpg"
                    alt="System Design"
                  />
                </Top>
                <Bottom>
                  <Head>System Design</Head>
                  <Info>
                    We offer system design services for effective automated
                    robotics systems. Our high-quality design techniques and
                    experienced engineers craft reliable systems.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t4.ftcdn.net/jpg/05/97/62/21/240_F_597622101_hIhc12IHbZrerlYd46yPpQsUZ3nvZPax.jpg"
                    alt="Robot Design"
                  />
                </Top>
                <Bottom>
                  <Head>Robot Design</Head>
                  <Info>
                    We offer robot design services for varied range of
                    functionalities and varied applications. Our well-designed
                    and quality-driven robots are reliable and powerful.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
            <div>
              <Slide>
                <Top>
                  <img
                    src="https://t3.ftcdn.net/jpg/06/15/77/06/240_F_615770683_mj8QkofmIzpDGdlGMZ8szXrGzoRwK0bm.jpg"
                    alt="Consultancy"
                  />
                </Top>
                <Bottom>
                  <Head>Consultancy</Head>
                  <Info>
                    We offer streamlined consultancy services for robot-based
                    automation systems for an array of applications. Our
                    services enable problem-free implementation of automation
                    systems.
                  </Info>
                  <Button>
                    <Nav to="/services">Read More</Nav>
                  </Button>
                </Bottom>
              </Slide>
            </div>
          </Carousel>
        </Wrapper>
      </Container>
    </>
  );
}
